import * as t from './types';

const initialState: t.SubscriptionReportState = {
  hasError: false,
  isLoading: false,
  reports: {
    isLoading: false,
    isLoadingMore: false,
    hasError: false,
    page: 0,
    totalPages: 0,
    items: {},
  },
  formData: {
    offers: [],
  },
};

const subscriptionReportReducer = (
  state = initialState,
  action: t.SubscriptionReportActionTypes
): t.SubscriptionReportState => {
  switch (action.type) {
    case t.COMMON_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case t.COMMON_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formData: action.payload,
      };

    case t.COMMON_DATA_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case t.REPORTS_ERROR:
      return {
        ...state,
        reports: { ...state.reports, isLoading: false, hasError: true },
      };

    case t.REPORTS_LOAD:
      return {
        ...state,
        reports: { ...state.reports, isLoading: true, items: {} },
      };

    case t.REPORTS_MORE_LOAD:
      return {
        ...state,
        reports: { ...state.reports, isLoading: false, isLoadingMore: true },
      };

    case t.REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          isLoading: false,
          isLoadingMore: false,
          hasError: false,
          page: action.payload.page,
          totalPages: action.payload.total_pages,
          items: { ...state.reports.items, ...action.payload.months },
        },
      };

    default:
      return state;
  }
};

export default subscriptionReportReducer;
