import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Dropdown } from 'semantic-ui-react';
import { CommonDataState } from 'store/common/types';
import { AppState } from 'store';
import { api } from 'api/utils';
import settings from 'helpers/settings';
import { mapStringOptionsFlag } from 'helpers/inputHelper';
import { FlashMessageType } from 'store/flashMessages/types';
import { addFlashMessage } from 'store/flashMessages/actions';

import s from './DownloadModal.module.css';

type Props = {
  title: string | undefined;
  onClose: () => void;
};

const URL = `${settings.API_URL}/admin/translations`;

const DownloadModal: React.FC<Props> = ({ title, onClose }) => {
  const { locales } = useSelector<AppState, CommonDataState>(
    (store) => store.commonData
  );
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState('');
  const dispatch = useDispatch();

  const onDownload = () => {
    const downloadFile = async () => {
      setLoading(true);
      try {
        const requestUrl = `${URL}/export?locale=${locale}&model=${title}`;
        const response = await api('GET', requestUrl);
        const blob = new Blob([JSON.stringify(response, null, 2)], {
          type: 'application/json',
        });
        const link = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = link;
        a.download = `${title}_${locale}.json`;
        a.click();
        window.URL.revokeObjectURL(link);
        onClose();
      } catch (error) {
        dispatch(
          addFlashMessage({
            header: 'Error occured',
            content: `${error}`,
            messageType: FlashMessageType.error,
          })
        );
      }
      setLoading(false);
    };

    downloadFile();
  };

  return (
    <>
      <Modal.Header>Download {title} translation file</Modal.Header>
      <Modal.Content>
        <Dropdown
          className={s.dropdown}
          placeholder="Select locale"
          fluid
          selection
          options={mapStringOptionsFlag(locales)}
          onChange={(e, { value }) => {
            setLocale(value as string);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon="cancel"
          labelPosition="right"
          content="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          positive
          icon="download"
          labelPosition="right"
          content="Download"
          disabled={loading || !locale}
          loading={loading}
          onClick={onDownload}
        />
      </Modal.Actions>
    </>
  );
};

export default DownloadModal;
