import {
  OfferState,
  FormDataType,
  EditOffer,
  OfferActionTypes,
  OFFER_LIST_LOAD,
  OFFER_FORM_DATA_LOAD,
  OFFER_LIST_SUCCESS,
  OFFER_LIST_ERROR,
  OFFER_FORM_DATA_ERROR,
  OFFER_FORM_DATA_SUCCESS,
  OFFER_CREATE,
  OFFER_CREATE_SUCCESS,
  CLEAR_CREATE_ERRORS,
  OFFER_CREATE_ERROR,
  REQUEST_OFFER,
  REQUEST_OFFER_ERROR,
  OFFER_EDIT_ERROR,
  REQUEST_OFFER_SUCCESS,
  OFFER_DUPLICATE,
  OFFER_DUPLICATE_SUCCESS,
  OFFER_DUPLICATE_ERROR,
  OFFER_ADD_NEW_PRICE,
  OFFER_ADD_NEW_PRICE_SUCCESS,
  OFFER_ADD_NEW_PRICE_ERROR,
} from './types';

const initialState: OfferState = {
  isLoading: false,
  hasError: false,
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 100,
      total: 1,
    },
  },
  formData: {
    currencies: [],
    categories: [],
    locales: [],
    countries: [],
    firstDataAccounts: [],
    offerTypes: [],
    states: [],
    paymodes: [],
    purchaseItems: [],
    pixelAccounts: [],
  } as FormDataType,
  create: {
    isLoading: false,
    hasError: false,
  },
  edit: {
    isLoading: false,
    hasError: false,
    offer: {} as EditOffer,
  },
  duplicate: {
    isLoading: false,
    hasError: false,
  },
  newPrice: {
    isLoading: false,
    hasError: false,
    isSuccess: false,
  },
};
const offerReducer = (
  state = initialState,
  action: OfferActionTypes
): OfferState => {
  switch (action.type) {
    case OFFER_LIST_LOAD:
    case OFFER_FORM_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case OFFER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case OFFER_LIST_ERROR:
    case OFFER_FORM_DATA_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case OFFER_FORM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        formData: action.payload,
      };

    case OFFER_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
        },
      };

    case OFFER_CREATE_SUCCESS:
    case CLEAR_CREATE_ERRORS:
      return {
        ...state,
        create: {
          hasError: false,
          isLoading: false,
        },
      };

    case OFFER_CREATE_ERROR:
      return {
        ...state,
        create: {
          hasError: true,
          isLoading: false,
        },
      };

    case REQUEST_OFFER:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };

    case REQUEST_OFFER_ERROR:
    case OFFER_EDIT_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          hasError: true,
          isLoading: false,
        },
      };

    case REQUEST_OFFER_SUCCESS:
      return {
        ...state,
        edit: {
          hasError: false,
          isLoading: false,
          offer: action.payload,
        },
      };

    case OFFER_DUPLICATE:
      return {
        ...state,
        duplicate: {
          hasError: false,
          isLoading: true,
        },
      };

    case OFFER_DUPLICATE_SUCCESS:
      return {
        ...state,
        duplicate: {
          hasError: false,
          isLoading: false,
        },
      };

    case OFFER_DUPLICATE_ERROR:
      return {
        ...state,
        duplicate: {
          hasError: true,
          isLoading: false,
        },
      };

    case OFFER_ADD_NEW_PRICE:
      return {
        ...state,
        newPrice: {
          hasError: false,
          isLoading: true,
          isSuccess: false,
        },
      };

    case OFFER_ADD_NEW_PRICE_SUCCESS:
      return {
        ...state,
        newPrice: {
          hasError: false,
          isLoading: false,
          isSuccess: true,
        },
      };

    case OFFER_ADD_NEW_PRICE_ERROR:
      return {
        ...state,
        newPrice: {
          hasError: true,
          isLoading: false,
          isSuccess: false,
        },
      };

    default:
      return state;
  }
};

export default offerReducer;
