import * as t from './types';

export const initialState: t.PurchaseItemsStateType = {
  purchaseItems: {
    isLoading: false,
    hasError: false,
    hasLoaded: false,
  },
  supportPurchaseItems: { isLoading: false, hasError: false, hasLoaded: false },
};

const purchaseItemsReducer = (
  state = initialState,
  action: t.PurchaseItemsActionTypes
): t.PurchaseItemsStateType => {
  switch (action.type) {
    case t.PURCHASE_ITEMS_LOAD:
      return {
        ...state,
        purchaseItems: {
          ...state.purchaseItems,
          isLoading: true,
          hasError: false,
          hasLoaded: false,
        },
      };

    case t.PURCHASE_ITEMS_SUCCESS:
      return {
        ...state,
        purchaseItems: {
          ...state.purchaseItems,
          isLoading: false,
          hasError: false,
          hasLoaded: true,
          purchaseItems: action.payload,
        },
      };

    case t.PURCHASE_ITEMS_ERROR:
      return {
        ...state,
        purchaseItems: {
          ...state.purchaseItems,
          isLoading: false,
          hasError: true,
          hasLoaded: false,
        },
      };

    case t.SUPPORT_PURCHASE_ITEMS_LOAD:
      return {
        ...state,
        supportPurchaseItems: {
          ...state.supportPurchaseItems,
          isLoading: true,
          hasError: false,
          hasLoaded: false,
        },
      };

    case t.SUPPORT_PURCHASE_ITEMS_SUCCESS:
      return {
        ...state,
        supportPurchaseItems: {
          ...state.supportPurchaseItems,
          isLoading: false,
          hasError: false,
          hasLoaded: true,
          supportPurchaseItems: action.payload,
        },
      };

    case t.SUPPORT_PURCHASE_ITEMS_ERROR:
      return {
        ...state,
        purchaseItems: {
          ...state.purchaseItems,
          isLoading: false,
          hasError: true,
          hasLoaded: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default purchaseItemsReducer;
