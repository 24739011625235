import { PaginationMetaData } from 'types/pagination';
import { CurrencyType, SubscriptionStatusType } from '../users/types';

export interface EarlyWarningResponse {
  data: EarlyWarningListItem[];
  meta: PaginationMetaData;
}

export interface EarlyWarningCurrentResponse {
  data: EarlyWarningListItem;
}

export type EarlyWarningFilters = {
  page: number | string;
};

export type EarlyWarningSavePayload = {
  identifier: string;
};

export type EarlyWarningStatusChangePayload = {
  early_warning: {
    status: EarlyWarningStatus;
  };
};

export type EarlyWarningRefundPayload = {
  refund_reasons: string[];
  payment_id: number;
};

// Mapping status by colors
export interface EarlyWarningMapping {
  status: object;
}

export enum EarlyWarningStatus {
  refunded = 'ALREADY_REFUNDED',
  completed = 'COMPLETED',
  auto_completed = 'AUTO_COMPLETED',
}

export const RDR_MSG = 'New RDR';

export interface EarlyWarningPayments {
  id: number;
  created_at: string;
  status: string;
  provider: string;
  price: string;
  amount: string;
  amount_in_euro: string;
  currency: CurrencyType;
  card: {
    last_digits: string;
    paymode: {
      id: number;
      name: string;
    };
  };
  status_name: string;
  identifier: string;
  provider_name: string;
  offer_id: string;
  invoice_id: string;
  refunded_at: string;
  refunded_amount?: null | string;
  refunded_percentage?: null | string;
  brand?: string;
  purchase_item: {
    name: string;
    title: string;
    frequency: number;
  };
  user: {
    id: number;
    category: string;
    email: string;
    country: string;
    phone_number: string;
    phone_prefix: string;
    locale: string;
    first_name: string;
  };
  processor?: null | string;
  ticket: {
    id: number;
    paid_from: string;
    paid_till: string;
    payment_id: number;
    subscription_id: number;
  };
  subscription: {
    status: SubscriptionStatusType;
  };
  is_trial: string;
  disputed_at: null | string;
}

export interface EarlyWarningCompletedBy {
  first_name: string;
  last_name: string;
}

export interface EarlyWarningListItem {
  id: number | string;
  service_name: string;
  status: string;
  external_id: string;
  external_sync_at: string;
  payment_amount: number | string;
  payment_amount_with_cents: number | string;
  payment_currency: string;
  payment_descriptor: string;
  payment_created_at: string;
  created_at: string;
  received_at: string;
  card_last_digits: string;
  card_number: string;
  card_issuer: string;
  payments_count: number | string;
  payments: EarlyWarningPayments[];
  payment: EarlyWarningPayments | null;
  completed_by: EarlyWarningCompletedBy | null;
  [key: string]:
    | string
    | number
    | string[]
    | EarlyWarningPayments[]
    | EarlyWarningPayments
    | EarlyWarningCompletedBy
    | null;
}

export type EarlyWarningListType = EarlyWarningResponse;
export type EarlyWarningCurrentType = EarlyWarningCurrentResponse;
export type EarlyWarningStateType = EarlyWarningState;

export interface EarlyWarningState {
  isLoading: boolean;
  hasError: boolean;
  listItems: EarlyWarningResponse;
  current: EarlyWarningCurrentResponse;
  mapping: EarlyWarningMapping;
  actionState: {
    paymentRefundSave: {
      isLoading: boolean;
      hasUpdated: boolean;
      error?: Error;
    };
    earlyWarningSave: {
      isLoading: boolean;
      hasUpdated: boolean;
      error?: Error;
    };
  };
}

// PAGE PATH
export const PAGE_PATH = '/customers/early_warning';

// Action names

// Early warning list
export const EARLY_WARNING_LIST_LOAD = 'EARLY_WARNING_LIST_LOAD';
export const EARLY_WARNING_LIST_SUCCESS = 'EARLY_WARNING_LIST_SUCCESS';
export const EARLY_WARNING_LIST_ERROR = 'EARLY_WARNING_LIST_ERROR';

// Early warning current list item
export const EARLY_WARNING_CURRENT_LOAD = 'EARLY_WARNING_CURRENT_LOAD';
export const EARLY_WARNING_CURRENT_SUCCESS = 'EARLY_WARNING_CURRENT_SUCCESS';
export const EARLY_WARNING_CURRENT_ERROR = 'EARLY_WARNING_CURRENT_ERROR';

// Early warning current list item save
export const EARLY_WARNING_CURRENT_SAVE = 'EARLY_WARNING_CURRENT_SAVE';
export const EARLY_WARNING_CURRENT_SAVE_SUCCESS =
  'EARLY_WARNING_CURRENT_SAVE_SUCCESS';
export const EARLY_WARNING_CURRENT_SAVE_ERROR =
  'EARLY_WARNING_CURRENT_SAVE_ERROR';

// Early warning payment refund
export const EARLY_WARNING_REFUND_SAVE = 'EARLY_WARNING_REFUND_SAVE';
export const EARLY_WARNING_REFUND_SAVE_SUCCESS =
  'EARLY_WARNING_REFUND_SAVE_SUCCESS';
export const EARLY_WARNING_REFUND_SAVE_ERROR =
  'EARLY_WARNING_REFUND_SAVE_ERROR';

interface EarlyWarningListLoadAction {
  type: typeof EARLY_WARNING_LIST_LOAD;
}

interface EarlyWarningListSuccess {
  type: typeof EARLY_WARNING_LIST_SUCCESS;
  payload: EarlyWarningResponse;
}

interface EarlyWarningListError {
  type: typeof EARLY_WARNING_LIST_ERROR;
}

interface EarlyWarningCurrentLoadAction {
  type: typeof EARLY_WARNING_CURRENT_LOAD;
}

interface EarlyWarningCurrentSuccess {
  type: typeof EARLY_WARNING_CURRENT_SUCCESS;
  payload: EarlyWarningCurrentResponse;
}

interface EarlyWarningCurrentError {
  type: typeof EARLY_WARNING_CURRENT_ERROR;
}

interface EarlyWarningCurrentSaveAction {
  type: typeof EARLY_WARNING_CURRENT_SAVE;
}

interface EarlyWarningCurrentSaveSuccess {
  type: typeof EARLY_WARNING_CURRENT_SAVE_SUCCESS;
}

interface EarlyWarningCurrentSaveError {
  type: typeof EARLY_WARNING_CURRENT_SAVE_ERROR;
  payload: Error;
}

interface EarlyWarningRefundSaveAction {
  type: typeof EARLY_WARNING_REFUND_SAVE;
}

interface EarlyWarningRefundSaveSuccess {
  type: typeof EARLY_WARNING_REFUND_SAVE_SUCCESS;
}

interface EarlyWarningRefundSaveError {
  type: typeof EARLY_WARNING_REFUND_SAVE_ERROR;
  payload: Error;
}

export type EarlyWarningActionTypes =
  | EarlyWarningListLoadAction
  | EarlyWarningListSuccess
  | EarlyWarningListError
  | EarlyWarningCurrentLoadAction
  | EarlyWarningCurrentSuccess
  | EarlyWarningCurrentError
  | EarlyWarningCurrentSaveAction
  | EarlyWarningCurrentSaveSuccess
  | EarlyWarningCurrentSaveError
  | EarlyWarningRefundSaveAction
  | EarlyWarningRefundSaveSuccess
  | EarlyWarningRefundSaveError;
