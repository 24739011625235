import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'helpers/auth';

class PrivateRoute extends Route {
  public render() {
    if (!isAuthenticated()) {
      return <Redirect to="/login" />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...this.props} />;
  }
}

export default PrivateRoute;
