/* eslint-disable import/prefer-default-export */
import * as t from './types';

const initialState: t.UserSupportInteractionsState = {
  isLoading: false,
  error: null,
  data: null,
};

const userSupportInteractionsReducer = (
  state = initialState,
  action: t.UserSupportInteractionActionTypes
): t.UserSupportInteractionsState => {
  switch (action.type) {
    case t.USER_SUPPORT_INTERACTIONS_GET:
      return {
        ...state,
        isLoading: true,
      };

    case t.USER_SUPPORT_INTERACTIONS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload || null,
      };

    case t.USER_SUPPORT_INTERACTIONS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userSupportInteractionsReducer;
