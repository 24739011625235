import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import {
  FlashMessagesState,
  FlashMessageType,
} from 'store/flashMessages/types';
import { removeFlashMessage } from 'store/flashMessages/actions';

import { Message } from 'semantic-ui-react';
import s from './FlashMessages.module.css';

const FlashMessages: React.FC = () => {
  const dispatch = useDispatch();
  const { list } = useSelector<AppState, FlashMessagesState>(
    (state) => state.flashMessages
  );

  useEffect(() => {
    list.map((item) =>
      setTimeout(() => {
        dispatch(removeFlashMessage(item.id));
      }, item.expiryMs || 3000)
    );
  }, [dispatch, list]);

  return (
    <div className={s.container}>
      {list.map(({ id, header, content, messageType }) => (
        <Message
          key={id}
          className={s.message}
          error={messageType === FlashMessageType.error}
          success={messageType === FlashMessageType.success}
          header={header}
          content={content}
        />
      ))}
    </div>
  );
};

export default FlashMessages;
