import * as t from './types';

export const addFlashMessage = (
  message: t.FlashMessage
): t.FlashMessagesActionTypes => ({
  type: t.FLASH_MESSAGE_ADD,
  message: { ...message, id: Math.random() },
});

export const removeFlashMessage = (
  id?: number
): t.FlashMessagesActionTypes => ({
  type: t.FLASH_MESSAGE_REMOVE,
  id,
});
