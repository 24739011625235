import { LocalizedValues } from 'types';

export interface FitnessProgramsListItem {
  id: string;
  title: string;
  code: string;
  updated: string;
}

export interface WorkoutItem {
  title: string;
  id: number;
  code: string;
}

export interface FormData {
  states: Array<string>;
  workouts: WorkoutItem[];
}

export interface Workout {
  code: string;
  id: number;
  title: string;
  editMode?: boolean;
}

export interface FitnessProgramData {
  updated: string;
  code: string;
  state: string;
  titles: LocalizedValues<string>;
  workouts: Workout[];
}

export interface FitnessEditState {
  isLoading: boolean;
  hasError: boolean;
  program: FitnessProgramData;
}

export interface FitnessSubmitState {
  isLoading: boolean;
  hasError: boolean;
  error?: Error;
}

// FitnessPrograms part of global store
export interface FitnessProgramsState {
  isLoading: boolean;
  hasError: boolean;
  listItems: Array<FitnessProgramsListItem>;
  edit: FitnessEditState;
  formData: FormData;
  submit: FitnessSubmitState;
}

export const FITNESS_LIST_LOAD = 'FITNESS_LIST_LOAD';
export const FITNESS_LIST_SUCCESS = 'FITNESS_LIST_SUCCESS';
export const FITNESS_LIST_ERROR = 'FITNESS_LIST_ERROR';

export const FITNESS_FORM_DATA_LOAD = 'FITNESS_FORM_DATA_LOAD';
export const FITNESS_FORM_DATA_SUCCESS = 'FITNESS_FORM_DATA_SUCCESS';
export const FITNESS_FORM_DATA_ERROR = 'FITNESS_FORM_DATA_ERROR';

export const FITNESS_EDIT_DATA_LOAD = 'FITNESS_EDIT_DATA_LOAD';
export const FITNESS_EDIT_DATA_SUCCESS = 'FITNESS_EDIT_DATA_SUCCESS';
export const FITNESS_EDIT_DATA_ERROR = 'FITNESS_EDIT_DATA_ERROR';

export const FITNESS_UPDATE_PROGRAM_LOAD = 'FITNESS_UPDATE_PROGRAM_LOAD';
export const FITNESS_UPDATE_PROGRAM_SUCCESS = 'FITNESS_UPDATE_PROGRAM_SUCCESS';
export const FITNESS_UPDATE_PROGRAM_ERROR = 'FITNESS_UPDATE_PROGRAM_ERROR';

export const FITNESS_NEW_PROGRAM_LOAD = 'FITNESS_NEW_PROGRAM_LOAD';
export const FITNESS_NEW_PROGRAM_SUCCESS = 'FITNESS_NEW_PROGRAM_SUCCESS';
export const FITNESS_NEW_PROGRAM_ERROR = 'FITNESS_NEW_PROGRAM_ERROR';

interface FitnessListLoadAction {
  type: typeof FITNESS_LIST_LOAD;
}

interface FitnessListSuccess {
  type: typeof FITNESS_LIST_SUCCESS;
  payload: Array<FitnessProgramsListItem>;
}

interface FitnessListError {
  type: typeof FITNESS_LIST_ERROR;
}

interface FitnessFormDataLoadAction {
  type: typeof FITNESS_FORM_DATA_LOAD;
}

interface FitnessFormDataSuccess {
  type: typeof FITNESS_FORM_DATA_SUCCESS;
  payload: FormData;
}

interface FitnessFormDataError {
  type: typeof FITNESS_FORM_DATA_ERROR;
}

interface FitnessEditDataLoadAction {
  type: typeof FITNESS_EDIT_DATA_LOAD;
}

interface FitnessEditDataSuccess {
  type: typeof FITNESS_EDIT_DATA_SUCCESS;
  payload: FitnessProgramData;
}

interface FitnessEditDataError {
  type: typeof FITNESS_EDIT_DATA_ERROR;
}

interface FitnessUpdateProgramLoadAction {
  type: typeof FITNESS_UPDATE_PROGRAM_LOAD;
}

interface FitnessUpdateProgramSuccess {
  type: typeof FITNESS_UPDATE_PROGRAM_SUCCESS;
}

interface FitnessUpdateProgramError {
  type: typeof FITNESS_UPDATE_PROGRAM_ERROR;
  payload: Error;
}

interface FitnessNewProgramLoadAction {
  type: typeof FITNESS_NEW_PROGRAM_LOAD;
}

interface FitnessNewProgramSuccess {
  type: typeof FITNESS_NEW_PROGRAM_SUCCESS;
}

interface FitnessNewProgramError {
  type: typeof FITNESS_NEW_PROGRAM_ERROR;
  payload: Error;
}

export type FitnessActionTypes =
  | FitnessListLoadAction
  | FitnessListSuccess
  | FitnessListError
  | FitnessFormDataLoadAction
  | FitnessFormDataSuccess
  | FitnessFormDataError
  | FitnessEditDataLoadAction
  | FitnessEditDataSuccess
  | FitnessEditDataError
  | FitnessUpdateProgramLoadAction
  | FitnessUpdateProgramSuccess
  | FitnessUpdateProgramError
  | FitnessNewProgramLoadAction
  | FitnessNewProgramSuccess
  | FitnessNewProgramError;
