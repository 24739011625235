import history from 'helpers/history';

export const TOKEN_KEY = 'access_token';

export const login = (accessToken: string): void => {
  localStorage.setItem(TOKEN_KEY, accessToken);
};

export const logout = (): void => {
  localStorage.removeItem(TOKEN_KEY);
  history.push('/login');
};

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const isAuthenticated = () => {
  return !!getToken();
};
