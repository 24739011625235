/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import settings from 'helpers/settings';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Dropdown, Icon } from 'semantic-ui-react';
import { CommonDataState } from 'store/common/types';
import { AppState } from 'store';
import { api } from 'api/utils';
import { mapStringOptionsFlag } from 'helpers/inputHelper';
import { FlashMessageType } from 'store/flashMessages/types';
import { addFlashMessage } from 'store/flashMessages/actions';

import s from './UploadModal.module.css';

type Props = {
  title: string | undefined;
  onClose: () => void;
};

const URL = `${settings.API_URL}/admin/translations`;

const UploadModal: React.FC<Props> = ({ title, onClose }) => {
  const { locales } = useSelector<AppState, CommonDataState>(
    (store) => store.commonData
  );
  const [uploadedFile, setUploadedFile] = useState<FormData>();
  const [locale, setLocale] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const uploadJson = async () => {
    if (!canSubmit()) {
      return;
    }

    setLoading(true);

    try {
      const requestUrl = `${URL}/import?locale=${locale}&model=${title}`;
      await api('POST', requestUrl, uploadedFile);
      dispatch(
        addFlashMessage({
          header: 'Success',
          content: `File has been succesfully uploaded`,
          messageType: FlashMessageType.success,
        })
      );
      onClose();
    } catch (error) {
      setLoading(false);
      dispatch(
        addFlashMessage({
          header: 'Error occured',
          content: `${error}`,
          messageType: FlashMessageType.error,
        })
      );
    }
  };

  const onDrop = (file: File[]) => {
    if (!file[0]) {
      dispatch(
        addFlashMessage({
          header: 'Error',
          content: `File size is too big`,
          messageType: FlashMessageType.error,
        })
      );
    } else {
      const formData = new FormData();
      formData.append('translation_file', file[0]);
      formData.getAll('translation_file');

      setUploadedFile(formData);
    }
  };

  const canSubmit = () => {
    if (loading || !locale || !uploadedFile) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Modal.Header>Upload {title} translation file</Modal.Header>
      <Modal.Content>
        <Dropzone
          maxSize={2000000}
          multiple={false}
          accept=".json"
          onDrop={onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {uploadedFile ? (
                  <p className={s.dropzone}>
                    <Icon name="upload" color="green" size="large" /> <br />
                    File has been added, press Upload to continue
                  </p>
                ) : (
                  <p className={s.dropzone}>
                    <Icon name="upload" color="grey" size="large" /> <br />
                    Drag and drop your translation file here
                  </p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        <Dropdown
          className={s.dropdown}
          placeholder="Select locale"
          onChange={(e, { value }) => {
            setLocale(value as string);
          }}
          fluid
          selection
          options={mapStringOptionsFlag(locales)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon="cancel"
          labelPosition="right"
          content="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          positive
          icon="upload"
          labelPosition="right"
          content="Upload"
          onClick={uploadJson}
          loading={loading}
          disabled={!canSubmit()}
        />
      </Modal.Actions>
    </>
  );
};

export default UploadModal;
