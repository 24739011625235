import { PaginationMetaData, PaginationPageLinks } from 'types/pagination';
import settings from '../../helpers/settings';

export const urlBase = `${settings.API_URL}/admin/user-support-interactions`;

export type UserSupportInteractionReason =
  | 'refund'
  | 'unsubscribe'
  | 'nutrition_related'
  | 'plan_access'
  | 'health'
  | 'requesting_more_info'
  | 'other';

export type UserSupportInteractionChannel =
  | 'email'
  | 'form'
  | 'chat'
  | 'social'
  | 'phone';

export interface UserSupportInteraction {
  id?: number;
  user_id: number;
  channel: UserSupportInteractionChannel;
  reason: UserSupportInteractionReason;
  form_reason?: string;
  zendesk_ticket_id?: string;
  contacted_at: string;
  created_by_admin_user_id?: number;
  created_by_admin_user?: {
    id: number;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    image_url: string | null;
    google_id: string | null;
    zendesk_user_id: string | null;
  };
}

export interface UserSupportInteractions {
  data: UserSupportInteraction[] | null;
  links: PaginationPageLinks | null;
  meta: PaginationMetaData | null;
}

export type UserSupportInteractionsState = {
  isLoading: boolean;
  error: Error | null;
  data: UserSupportInteractions | null;
};

// API

export interface UserSupportInteractionApiGetParams {
  user_id: number;
  page?: number;
}

export interface UserSupportInteractionApiCreateParams {
  user_id: number;
  channel: UserSupportInteractionChannel;
  reason: UserSupportInteractionReason;
  contacted_at: string;
  zendesk_ticket_id?: string;
  form_reason?: string;
}

export interface UserSupportInteractionApiUpdateParams {
  id: number;
  channel: UserSupportInteractionChannel;
  reason: UserSupportInteractionReason;
  contacted_at: string;
  zendesk_ticket_id?: string;
  form_reason?: string;
}

export interface UserSupportInteractionApiDeleteParams {
  id: number;
}

// Redux actions

export const USER_SUPPORT_INTERACTIONS_GET = 'USER_SUPPORT_INTERACTIONS_GET';
export const USER_SUPPORT_INTERACTIONS_GET_SUCCESS =
  'USER_SUPPORT_INTERACTIONS_SUCCESS';
export const USER_SUPPORT_INTERACTIONS_GET_ERROR =
  'USER_SUPPORT_INTERACTIONS_ERROR';

export const USER_SUPPORT_INTERACTION_CREATE =
  'USER_SUPPORT_INTERACTION_CREATE';
export const USER_SUPPORT_INTERACTION_CREATE_SUCCESS =
  'USER_SUPPORT_INTERACTION_CREATE_SUCCESS';
export const USER_SUPPORT_INTERACTION_CREATE_ERROR =
  'USER_SUPPORT_INTERACTION_CREATE_ERROR';

export const USER_SUPPORT_INTERACTION_UPDATE =
  'USER_SUPPORT_INTERACTION_UPDATE';
export const USER_SUPPORT_INTERACTION_UPDATE_SUCCESS =
  'USER_SUPPORT_INTERACTION_UPDATE_SUCCESS';
export const USER_SUPPORT_INTERACTION_UPDATE_ERROR =
  'USER_SUPPORT_INTERACTION_UPDATE_ERROR';

export const USER_SUPPORT_INTERACTION_DELETE =
  'USER_SUPPORT_INTERACTION_DELETE';
export const USER_SUPPORT_INTERACTION_DELETE_SUCCESS =
  'USER_SUPPORT_INTERACTION_DELETE_SUCCESS';
export const USER_SUPPORT_INTERACTION_DELETE_ERROR =
  'USER_SUPPORT_INTERACTION_DELETE_ERROR';

interface SimpleAction<Type> {
  type: Type;
}

interface Action<Type, Payload> {
  type: Type;
  payload: Payload;
}

type UserSupportInteractionsGetAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTIONS_GET
>;
type UserSupportInteractionsGetSuccessAction = Action<
  typeof USER_SUPPORT_INTERACTIONS_GET_SUCCESS,
  UserSupportInteractions
>;
type UserSupportInteractionsGetErrorAction = Action<
  typeof USER_SUPPORT_INTERACTIONS_GET_ERROR,
  Error
>;

type UserSupportInteractionCreateAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_CREATE
>;
type UserSupportInteractionCreateSuccessAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_CREATE_SUCCESS
>;
type UserSupportInteractionCreateErrorAction = Action<
  typeof USER_SUPPORT_INTERACTION_CREATE_ERROR,
  Error
>;

type UserSupportInteractionUpdateAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_UPDATE
>;
type UserSupportInteractionUpdateSuccessAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_UPDATE_SUCCESS
>;
type UserSupportInteractionUpdateErrorAction = Action<
  typeof USER_SUPPORT_INTERACTION_UPDATE_ERROR,
  Error
>;

type UserSupportInteractionDeleteAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_DELETE
>;
type UserSupportInteractionDeleteSuccessAction = SimpleAction<
  typeof USER_SUPPORT_INTERACTION_DELETE_SUCCESS
>;
type UserSupportInteractionDeleteErrorAction = Action<
  typeof USER_SUPPORT_INTERACTION_DELETE_ERROR,
  Error
>;

export type UserSupportInteractionActionTypes =
  | UserSupportInteractionsGetAction
  | UserSupportInteractionsGetSuccessAction
  | UserSupportInteractionsGetErrorAction
  | UserSupportInteractionCreateAction
  | UserSupportInteractionCreateSuccessAction
  | UserSupportInteractionCreateErrorAction
  | UserSupportInteractionUpdateAction
  | UserSupportInteractionUpdateSuccessAction
  | UserSupportInteractionUpdateErrorAction
  | UserSupportInteractionDeleteAction
  | UserSupportInteractionDeleteSuccessAction
  | UserSupportInteractionDeleteErrorAction;
