import * as t from './types';

const initialState: t.FlashMessagesState = {
  list: [],
};

const flashMessageReducer = (
  state = initialState,
  action: t.FlashMessagesActionTypes
): t.FlashMessagesState => {
  switch (action.type) {
    case t.FLASH_MESSAGE_ADD:
      return {
        ...state,
        list: [...state.list, action.message],
      };

    case t.FLASH_MESSAGE_REMOVE:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };

    default:
      return {
        ...state,
      };
  }
};

export default flashMessageReducer;
