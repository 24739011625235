import { useState } from 'react';
import { api } from '../../../api/utils';

type Params = {
  uploadUrl: string;
  onUploadSuccess: () => void;
  onUploadError: (error: string) => void;
};

type Return = [boolean, boolean, (file: File[]) => void, () => void];

const useFileImport = ({
  uploadUrl,
  onUploadSuccess,
  onUploadError,
}: Params): Return => {
  const [uploadedFile, setUploadedFile] = useState<File[]>();
  const [loading, setLoading] = useState(false);

  const hasFileSubmitted = !!uploadedFile;

  const onFileSubmit = (files: File[]) => {
    setUploadedFile(files);
  };

  const triggerUpload = async () => {
    if (!uploadedFile) {
      return;
    }

    setLoading(true);
    try {
      const fliesUploadedPromises = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const file of uploadedFile) {
        const formData = new FormData();
        formData.append('file', file);
        formData.getAll('file');
        const asyncUpload = api('POST', uploadUrl, formData);
        fliesUploadedPromises.push(asyncUpload);
      }
      await Promise.all(fliesUploadedPromises);

      onUploadSuccess();
    } catch (error) {
      onUploadError(error);
    } finally {
      setLoading(false);
    }
  };

  return [loading, hasFileSubmitted, onFileSubmit, triggerUpload];
};

export default useFileImport;
