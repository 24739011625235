import * as t from './types';

const initialState: t.ExerciseState = {
  isLoading: false,
  hasError: false,
  listItems: [],
  edit: {
    isLoading: false,
    hasError: false,
    exercise: {
      calories: '',
      code: '',
      defaultVideo: '',
      descriptions: {},
      durationMax: '',
      durationMin: '',
      durationStep: '',
      group: '',
      repetitions: '',
      repetitionsMax: '',
      repetitionsMin: '',
      repetitionsStep: '',
      titles: {},
      updated: '',
      videos: {},
      images: {},
    },
  },
  submit: {
    isLoading: false,
  },
};

const exercisesReducer = (
  state = initialState,
  action: t.ExerciseActionTypes
): t.ExerciseState => {
  switch (action.type) {
    case t.EXERCISE_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.EXERCISE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listItems: action.payload,
      };
    case t.EXERCISE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.EXERCISE_EDIT_DATA_LOAD:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };
    case t.EXERCISE_EDIT_DATA_SUCCESS:
      return {
        ...state,
        edit: {
          isLoading: false,
          hasError: false,
          exercise: action.payload,
        },
      };
    case t.EXERCISE_EDIT_DATA_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          hasError: true,
        },
      };
    case t.EXERCISE_NEW_LOAD:
    case t.EXERCISE_UPDATE_LOAD:
      return {
        ...state,
        submit: {
          isLoading: true,
        },
      };
    case t.EXERCISE_NEW_SUCCESS:
    case t.EXERCISE_UPDATE_SUCCESS:
      return {
        ...state,
        submit: {
          isLoading: false,
        },
      };
    case t.EXERCISE_NEW_ERROR:
    case t.EXERCISE_UPDATE_ERROR:
      return {
        ...state,
        submit: {
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default exercisesReducer;
