// whatwg-fetch -> Browser polyfill for fetch() method. More info: https://github.com/github/fetch#readme
import 'whatwg-fetch';
import { logout, getToken } from 'helpers/auth';

type BodyType = object | FormData | string;

export const requestHeaders = (headersObject?: HeadersInit) => {
  const token = getToken();

  return {
    'App-Version': process.env.REACT_APP_VERSION || '1.0.0',
    ...(token && { Authorization: token }),
    ...headersObject,
  };
};

const getApiBody = (body?: BodyType) => {
  if (body instanceof FormData) {
    return body;
  }

  return JSON.stringify(body);
};

const getApiHeaders = (body?: BodyType) => {
  if (body instanceof FormData) {
    return requestHeaders();
  }

  return requestHeaders({ 'Content-Type': 'application/json' });
};

export const api = async <T>(
  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  body?: object | FormData | string,
  overrideHeaders?: HeadersInit
): Promise<T> => {
  const headers = getApiHeaders(body);

  const options = {
    method,
    body: getApiBody(body),
    headers: {
      ...headers,
      ...overrideHeaders,
    },
  };

  const response = await fetch(url, options);

  if (response.status === 401) {
    logout();
  }

  if (response.status === 204) {
    return {} as T;
  }

  const data = await response.json();

  if (!response.ok) {
    const error = new Error(
      data.error.message ||
        data.error.detail ||
        response.statusText ||
        response.status
    );
    error.name = data.error.title || 'Error';
    throw error;
  }
  return data;
};
