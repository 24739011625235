import React, { FC } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import s from '../Upload/UploadModal.module.css';
import settings from '../../../helpers/settings';
import useFileImport from './useFileImport';
import { addFlashMessage } from '../../../store/flashMessages/actions';
import { FlashMessageType } from '../../../store/flashMessages/types';

const URL = `${settings.API_URL}/admin/retention-templates/import`;
const ACCEPTED_FILE_TYPES = [
  '.csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

type Props = {
  onClose: () => void;
};

const RetentionTemplateImportModal: FC<Props> = ({ onClose }) => {
  const TWO_MEGABYTES = 2 * 1024 * 1024;

  const dispatch = useDispatch();

  const onUploadSuccess = () => {
    dispatch(
      addFlashMessage({
        header: 'Success',
        content: `File has been successfully uploaded`,
        messageType: FlashMessageType.success,
      })
    );
    onClose();
    window.location.reload();
  };

  const onUploadError = (error: string) => {
    dispatch(
      addFlashMessage({
        header: 'Error',
        content: `${error}`,
        messageType: FlashMessageType.error,
      })
    );
  };

  const [
    loading,
    hasFileSubmitted,
    onFileSubmit,
    triggerUpload,
  ] = useFileImport({
    uploadUrl: URL,
    onUploadSuccess,
    onUploadError,
  });

  const isSubmitDisabled = loading || !hasFileSubmitted;

  const handleUpload = () => {
    if (isSubmitDisabled) {
      return;
    }

    triggerUpload();
  };

  const handleDrop = (files: File[]) => {
    if (!files.length) {
      return;
    }
    onFileSubmit(files);
  };

  return (
    <>
      <Modal.Header>Upload retention template file</Modal.Header>
      <Modal.Content>
        <Dropzone
          multiple
          maxSize={TWO_MEGABYTES}
          accept={ACCEPTED_FILE_TYPES.join(',')}
          onDrop={handleDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <div {...getRootProps()}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                {hasFileSubmitted ? (
                  <p className={s.dropzone}>
                    <Icon name="upload" color="green" size="large" /> <br />
                    File has been added, press Upload to continue
                  </p>
                ) : (
                  <p className={s.dropzone}>
                    <Icon name="upload" color="grey" size="large" /> <br />
                    Drag and drop your file here
                  </p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon="cancel"
          labelPosition="right"
          content="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          positive
          icon="upload"
          labelPosition="right"
          content="Upload"
          onClick={handleUpload}
          loading={loading}
          disabled={isSubmitDisabled}
        />
      </Modal.Actions>
    </>
  );
};

export default RetentionTemplateImportModal;
