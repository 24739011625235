import { MonacofitCatgories } from 'types';
import { UserOrderType } from 'assets/constants';

export type LastCalled = {
  name: string;
  timestamp: string;
};

export type DialUserResponse = {
  adminUser: LastCalled;
  clientId: number;
};

export interface TelemarketingUser {
  id: number;
  name: string;
  age: number;
  email: string;
  phone: string;
  goal: string;
  category: MonacofitCatgories;
  startWeight: number;
  goalWeight: number;
  daysOnline: number;
  lastOnline: string;
  lastFailedOffer: string | null;
  lastCalled: LastCalled | null;
}

export interface TelemarketingUsers {
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  items: Array<TelemarketingUser>;
}

// Telemarketing part of global store
export interface TelemarketingState {
  isLoading: boolean;
  hasError: boolean;
  users: TelemarketingUsers;
  filters: Partial<TelemarketingFiltersType>;
}

type DaysOnline = {
  from: number;
  to: number;
};

type DateRange = {
  startDate: string;
  endDate: string;
};

export type TelemarketingFiltersType = {
  page: number;
  categories: Array<number>;
  locales: Array<string>;
  perPage: number;
  hideDialed: boolean;
  daysOnline: DaysOnline;
  createdAt: DateRange;
  lastOnline: DateRange;
  orderBy: Array<{
    orderBy: UserOrderType;
    direction: string;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const TELEMARKETING_USERS_LOAD = 'TELEMARKETING_USERS_LOAD';
export const TELEMARKETING_USERS_SUCCESS = 'TELEMARKETING_USERS_SUCCESS';
export const TELEMARKETING_USERS_ERROR = 'TELEMARKETING_USERS_ERROR';

export const RESET_FILTERS = 'RESET_FILTERS';

export const DIAL_USER = 'DIAL_USER';
export const DIAL_USER_SUCCESS = 'DIAL_USER_SUCCESS';
export const DIAL_USER_ERROR = 'DIAL_USER_ERROR';

interface TelemarketingUsersLoad {
  type: typeof TELEMARKETING_USERS_LOAD;
  payload: Partial<TelemarketingFiltersType>;
}

interface TelemarketingUsersSuccess {
  type: typeof TELEMARKETING_USERS_SUCCESS;
  payload: TelemarketingUsers;
}

interface TelemarketingUsersError {
  type: typeof TELEMARKETING_USERS_ERROR;
}

interface ResetFilters {
  type: typeof RESET_FILTERS;
}

interface DialUser {
  type: typeof DIAL_USER;
}

interface DialUserSuccess {
  type: typeof DIAL_USER_SUCCESS;
  payload: DialUserResponse;
}

interface DialUserError {
  type: typeof DIAL_USER_ERROR;
}

export type TelemarketingActionTypes =
  | TelemarketingUsersLoad
  | TelemarketingUsersSuccess
  | TelemarketingUsersError
  | ResetFilters
  | DialUser
  | DialUserSuccess
  | DialUserError;
