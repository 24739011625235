import {
  DISPUTE_TEMPLATE_GENERATED_SUCCESS,
  RETENTION_TEMPLATE_ACTION_SAVE,
  RETENTION_TEMPLATE_ACTION_SAVE_ERROR,
  RETENTION_TEMPLATE_ACTION_SAVE_SUCCESS,
  RETENTION_TEMPLATE_CURRENT_LOAD,
  RETENTION_TEMPLATE_CURRENT_SAVE,
  RETENTION_TEMPLATE_CURRENT_SAVE_ERROR,
  RETENTION_TEMPLATE_CURRENT_SAVE_SUCCESS,
  RETENTION_TEMPLATE_CURRENT_SUCCESS,
  RETENTION_TEMPLATE_EXECUTE,
  RETENTION_TEMPLATE_EXECUTE_RESET,
  RETENTION_TEMPLATE_EXECUTE_SUCCESS,
  RETENTION_TEMPLATE_GENERATED_ERROR,
  RETENTION_TEMPLATE_GENERATED_SUCCESS,
  RETENTION_TEMPLATE_LIST_ERROR,
  RETENTION_TEMPLATE_LIST_LOAD,
  RETENTION_TEMPLATE_LIST_SUCCESS,
  RetentionTemplateActionTypes,
  RetentionTemplateState,
} from './types';

const initialState: RetentionTemplateState = {
  isLoading: false,
  hasError: false,
  actionState: {
    retentionTemplateSave: {
      isLoading: false,
      hasUpdated: false,
    },
    retentionTemplateActionSave: {
      isLoading: false,
      hasUpdated: false,
    },
  },
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  current: {
    data: {
      id: '',
      name: '',
      status: '',
      type: '',
      created_at: '',
      updated_at: '',
      translations: [],
      actions: [],
      export_url: '',
    },
  },
  generated: {
    data: '',
  },
  generatedInModal: {
    data: '',
  },
  execute: {
    isLoading: false,
    data: [],
  },
};

const retentionTemplateReducer = (
  state = initialState,
  action: RetentionTemplateActionTypes
): RetentionTemplateState => {
  switch (action.type) {
    case RETENTION_TEMPLATE_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
        actionState: {
          retentionTemplateSave: {
            isLoading: false,
            hasUpdated: false,
          },
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case RETENTION_TEMPLATE_CURRENT_LOAD:
    case RETENTION_TEMPLATE_CURRENT_SAVE:
      return {
        ...state,
        actionState: {
          retentionTemplateSave: {
            isLoading: true,
            hasUpdated: false,
          },
          retentionTemplateActionSave: {
            isLoading: true,
            hasUpdated: false,
          },
        },
      };

    case RETENTION_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case RETENTION_TEMPLATE_CURRENT_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          retentionTemplateSave: {
            isLoading: false,
            hasUpdated: false,
            error: undefined,
          },
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: false,
            error: undefined,
          },
        },
      };

    case RETENTION_TEMPLATE_CURRENT_SAVE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          retentionTemplateSave: {
            isLoading: false,
            hasUpdated: true,
            error: undefined,
          },
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: false,
            error: undefined,
          },
        },
      };

    case RETENTION_TEMPLATE_CURRENT_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          retentionTemplateSave: {
            error: action.data,
            isLoading: false,
            hasUpdated: false,
          },
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: false,
            error: undefined,
          },
        },
      };

    case RETENTION_TEMPLATE_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RETENTION_TEMPLATE_GENERATED_SUCCESS:
      return {
        ...state,
        generated: {
          data: action.data.data,
        },
      };

    case DISPUTE_TEMPLATE_GENERATED_SUCCESS:
      return {
        ...state,
        generatedInModal: {
          data: action.data.data,
        },
      };

    case RETENTION_TEMPLATE_GENERATED_ERROR:
      return {
        ...state,
        generated: {
          data: '',
        },
      };

    case RETENTION_TEMPLATE_ACTION_SAVE:
      return {
        ...state,
        actionState: {
          ...state.actionState,
          retentionTemplateActionSave: {
            isLoading: true,
            hasUpdated: false,
          },
        },
      };

    case RETENTION_TEMPLATE_ACTION_SAVE_SUCCESS:
      return {
        ...state,
        actionState: {
          ...state.actionState,
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: true,
          },
        },
      };

    case RETENTION_TEMPLATE_ACTION_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          ...state.actionState,
          retentionTemplateActionSave: {
            isLoading: false,
            hasUpdated: false,
            error: action.data,
          },
        },
      };

    case RETENTION_TEMPLATE_EXECUTE:
      return {
        ...state,
        execute: {
          ...state.execute,
          isLoading: true,
        },
      };

    case RETENTION_TEMPLATE_EXECUTE_SUCCESS:
      return {
        ...state,
        execute: {
          data: action.data.data,
          isLoading: false,
        },
      };

    case RETENTION_TEMPLATE_EXECUTE_RESET:
      return {
        ...state,
        execute: {
          data: [],
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default retentionTemplateReducer;
