// More info: https://redux.js.org/recipes/usage-with-typescript
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { composeEnhancers } from './utils';
import offerReducer from './offers/reducers';
import subscriptionReportReducer from './subscriptionReport/reducers';
import userLifetimeValueReducer from './userLifetimeValue/reducers';
import fitnessProgramsReducer from './fitnessPrograms/reducers';
import exercisesReducer from './exercises/reducers';
import workoutsReducer from './workouts/reducers';
import productsReducer from './products/reducers';
import mealsReducer from './meals/reducers';
import tricksReducer from './tricks/reducers';
import userReducer from './users/reducers';
import testimonialReducer from './testimonials/reducers';
import telemarketingReducer from './telemarketing/reducers';
import commonDataReducer from './common/reducers';
import flashMessageReducer from './flashMessages/reducers';
import modalReducer from './modals/reducers';
import disputesReducer from './disputes/reducers';
import purchaseItemsReducer from './purchaseItems/reducers';
import earlyWarningReducer from './earlyWarning/reducers';
import paymentReducer from './payment/reducers';
import subscriptionReducer from './subscriptions/reducers';
import paymentProviderRuleReducer from './paymentProviderRules/reducers';
import auditEventReducer from './auditEvents/reducer';
import userSupportInteractionsReducer from './userSupportInteractions/reducer';
import retentionTemplateReducer from './retentionTemplates/reducers';

const rootReducer = combineReducers({
  offers: offerReducer,
  fitnessPrograms: fitnessProgramsReducer,
  exercises: exercisesReducer,
  workouts: workoutsReducer,
  products: productsReducer,
  meals: mealsReducer,
  tricks: tricksReducer,
  subscriptionReport: subscriptionReportReducer,
  userLifetimeValue: userLifetimeValueReducer,
  users: userReducer,
  testimonials: testimonialReducer,
  telemarketing: telemarketingReducer,
  commonData: commonDataReducer,
  flashMessages: flashMessageReducer,
  modals: modalReducer,
  disputes: disputesReducer,
  purchaseItems: purchaseItemsReducer,
  earlyWarning: earlyWarningReducer,
  payment: paymentReducer,
  subscription: subscriptionReducer,
  paymentProviderRule: paymentProviderRuleReducer,
  auditEvent: auditEventReducer,
  userSupportInteractions: userSupportInteractionsReducer,
  retentionTemplate: retentionTemplateReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const enchancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, enchancer);

  return store;
}
