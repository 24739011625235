import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { removeModal } from 'store/modals/actions';
import { ModalsState, OpenedModals, ModalsTypes } from 'store/modals/types';
import { Modal } from 'semantic-ui-react';
import DownloadModal from './Download/DownloadModal';
import UploadModal from './Upload/UploadModal';
import RetentionTemplateImportModal from './RetentionTemplateImport/RetentionTemplateImportModal';

const Modals: React.FC = () => {
  const dispatch = useDispatch();

  const { modals } = useSelector<AppState, ModalsState>(
    (state) => state.modals
  );

  const renderModal = (modal: OpenedModals) => {
    switch (modal.type) {
      case ModalsTypes.download:
        return (
          <DownloadModal
            title={modal.data?.from}
            onClose={() => dispatch(removeModal())}
          />
        );
      case ModalsTypes.upload:
        return (
          <UploadModal
            title={modal.data?.from}
            onClose={() => dispatch(removeModal())}
          />
        );
      case ModalsTypes.retentionTemplateImport:
        return (
          <RetentionTemplateImportModal
            onClose={() => dispatch(removeModal())}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      size="tiny"
      open={modals.length > 0}
      onClose={() => dispatch(removeModal())}
    >
      {modals.length > 0 && renderModal(modals[0])}
    </Modal>
  );
};

export default Modals;
