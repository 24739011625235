import { Gender, UserModel, GoalType } from 'types/user';

export enum TestimonialScope {
  Testimonial,
  'Total weight change',
}

export type FilterState = {
  scope: TestimonialScope;
  totalWeightChangeFrom: string;
  totalWeightChangeTo: string;
  locales: string[];
  goal: GoalType[];
  weightFrom: string;
  weightTo: string;
  ageFrom: string;
  ageTo: string;
  genders: Gender[];
};

export type FiltersApiSubmit = {
  scope: TestimonialScope;
  weight_change_from?: number;
  weight_change_to?: number;
  locales?: string[];
  goals?: GoalType[];
  weight_from?: number;
  weight_to?: number;
  age_from?: number;
  age_to?: number;
  genders?: Gender[];
  page?: number;
};

export type TestimonialAnswers = {
  experience: string;
  actions: string;
  failed: string;
  reason: string;
  referral: string;
  motivation: string;
  discipline: string;
  impression: string;
  results: string;
  recommendation: string;
};

type TestimonialModel = {
  id: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  email: string;
  answers: TestimonialAnswers;
  accepted_lottery: boolean;
};

export type TestimonialItemType = {
  user: UserModel;
  testimonial: TestimonialModel | null;
};

export type TestimonialResponse = {
  users: TestimonialItemType[];
  page: number;
  total_pages: number;
};

export type TestimonialState = {
  isLoading: boolean;
  error?: Error;
  hasLoaded: boolean;
  list?: TestimonialResponse;
  submitFilters?: FiltersApiSubmit;
};

export const TESTIMONIAL_LIST_LOAD = 'TESTIMONIAL_LIST_LOAD';
export const TESTIMONIAL_LIST_SUCCESS = 'TESTIMONIAL_LIST_SUCCESS';
export const TESTIMONIAL_LIST_ERROR = 'TESTIMONIAL_LIST_ERROR';

interface TestimonialListLoad {
  type: typeof TESTIMONIAL_LIST_LOAD;
  payload: FiltersApiSubmit;
}

interface TestimonialListSuccess {
  type: typeof TESTIMONIAL_LIST_SUCCESS;
  payload: TestimonialResponse;
}

interface TestimonialListError {
  type: typeof TESTIMONIAL_LIST_ERROR;
  payload: Error;
}

export type TestimonialActionTypes =
  | TestimonialListLoad
  | TestimonialListSuccess
  | TestimonialListError;
