import { api } from 'api/utils';
import settings from 'helpers/settings';
import { ThunkActionType } from 'store/types';
import * as t from './types';

const urlBase = `${settings.API_URL}/admin`;

const commonDataLoad = (): t.CommonDataActionTypes => ({
  type: t.COMMON_DATA_LOAD,
});

const commonDataSuccess = (payload: t.CommonData): t.CommonDataActionTypes => ({
  type: t.COMMON_DATA_SUCCESS,
  payload,
});

const commonDataError = (): t.CommonDataActionTypes => ({
  type: t.COMMON_DATA_ERROR,
});

// eslint-disable-next-line import/prefer-default-export
export const loadCommonData = (): ThunkActionType => async (dispatch) => {
  dispatch(commonDataLoad());

  try {
    const url = `${urlBase}/common-data`;
    const data = await api<t.CommonData>('GET', url);

    dispatch(commonDataSuccess(data));
  } catch {
    dispatch(commonDataError());
  }
};
