import * as t from './types';

const initialState: t.ProductsState = {
  isLoading: false,
  hasError: false,
  listItems: [],
  edit: {
    isLoading: false,
    hasError: false,
    product: {
      code: '',
      titles: {},
      updated: '',
      emoji: '',
      diets: [],
      mealSurveyVisible: t.Visibility.visible,
      unit: t.Unit.g,
      types: [],
      calories: 0,
      carbs: 0,
      fibre: 0,
      proteins: 0,
      fats: 0,
    },
  },
  submit: {
    isLoading: false,
    hasError: false,
  },
  types: [],
};

const productsReducer = (
  state = initialState,
  action: t.ProductActionTypes
): t.ProductsState => {
  switch (action.type) {
    case t.PRODUCTS_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listItems: action.payload,
      };
    case t.PRODUCTS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.PRODUCTS_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
      };
    case t.PRODUCTS_EDIT_LOAD:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };
    case t.PRODUCTS_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          isLoading: false,
          hasError: false,
          product: action.payload,
        },
      };
    case t.PRODUCTS_EDIT_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          hasError: true,
        },
      };
    case t.PRODUCTS_NEW_LOAD:
    case t.PRODUCTS_UPDATE_LOAD:
      return {
        ...state,
        submit: {
          isLoading: true,
          hasError: false,
        },
      };
    case t.PRODUCTS_NEW_SUCCESS:
    case t.PRODUCTS_UPDATE_SUCCESS:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: false,
        },
      };
    case t.PRODUCTS_NEW_ERROR:
    case t.PRODUCTS_UPDATE_ERROR:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: true,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default productsReducer;
