/* eslint-disable @typescript-eslint/camelcase */
import * as t from './types';

const initialState: t.PaymentState = {
  isLoading: false,
  hasError: false,
  payments: {
    isLoading: false,
    hasError: false,
    hasLoaded: false,
    data: {
      data: [],
      meta: {
        current_page: 1,
        total: 1,
        last_page: 1,
      },
    },
  },
  actionState: {
    paymentRefundSave: {
      isLoading: false,
      hasUpdated: false,
      hasError: false,
    },
  },
  mapping: {
    paymentStatus: {
      new: 'grey',
      completed: 'green',
      error: 'red',
      failed: 'red',
      refunded: 'yellow',
      already_refunded: 'yellow',
      partially_refunded: 'yellow',
    },
  },
};

const paymentReducer = (
  state = initialState,
  action: t.PaymentActionTypes
): t.PaymentState => {
  switch (action.type) {
    case t.PAYMENT_LIST_LOAD:
      return {
        ...state,
        payments: {
          isLoading: true,
          hasError: false,
          hasLoaded: false,
          data: state.payments.data,
        },
      };
    case t.PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        payments: {
          isLoading: false,
          hasError: false,
          hasLoaded: true,
          data: action.payload,
        },
      };
    case t.PAYMENT_LIST_ERROR:
      return {
        ...state,
        payments: {
          isLoading: false,
          hasError: true,
          hasLoaded: false,
          data: state.payments.data,
        },
      };

    case t.PAYMENT_REFUND_ACTION_START:
      return {
        ...state,
        actionState: {
          paymentRefundSave: {
            isLoading: true,
            hasError: false,
            hasUpdated: false,
          },
        },
      };
    case t.PAYMENT_REFUND_ACTION_SUCCESS:
      return {
        ...state,
        actionState: {
          paymentRefundSave: {
            isLoading: false,
            hasError: false,
            hasUpdated: true,
          },
        },
      };
    case t.PAYMENT_REFUND_ACTION_ERROR:
      return {
        ...state,
        actionState: {
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
            hasError: true,
            error: action.payload,
          },
        },
      };
    case t.PAYMENT_REFUND_ACTION_CLEAR:
      return {
        ...state,
        actionState: {
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
            hasError: null,
          },
        },
      };
    default: {
      return state;
    }
  }
};

export default paymentReducer;
