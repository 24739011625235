import * as t from './types';

const initialState: t.WorkoutsState = {
  isLoading: false,
  hasError: false,
  listItems: [],
  edit: {
    isLoading: false,
    hasError: false,
    workout: {
      code: '',
      descriptions: {},
      exercises: [],
      images: {},
      images_male: {},
      images_female: {},
      titles: {},
      updated: '',
      workoutTime: 0,
      showcaseExerciseId: null,
      benefits: [],
    },
    exerciseTypes: [],
  },
  submit: {
    isLoading: false,
    hasError: false,
  },
};

const workoutsReducer = (
  state = initialState,
  action: t.WorkoutActionTypes
): t.WorkoutsState => {
  switch (action.type) {
    case t.WORKOUTS_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.WORKOUTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listItems: action.payload,
      };
    case t.WORKOUTS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.WORKOUTS_EDIT_LOAD:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };
    case t.EXERCISE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          exerciseTypes: action.payload,
        },
      };
    case t.WORKOUTS_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          workout: action.payload,
        },
      };
    case t.EXERCISE_ERROR:
    case t.WORKOUTS_EDIT_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          hasError: true,
        },
      };
    case t.WORKOUTS_NEW_LOAD:
    case t.WORKOUTS_UPDATE_LOAD:
      return {
        ...state,
        submit: {
          isLoading: true,
          hasError: false,
        },
      };
    case t.WORKOUTS_NEW_SUCCESS:
    case t.WORKOUTS_UPDATE_SUCCESS:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: false,
        },
      };
    case t.WORKOUTS_NEW_ERROR:
    case t.WORKOUTS_UPDATE_ERROR:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: true,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default workoutsReducer;
