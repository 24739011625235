export type CommonDataItem = {
  text: string;
  value: string;
};

export type CommonDataResponse = {
  offers: CommonDataItem[];
};

export type ReportsResponse = {
  months: ReportItems;
  page: number;
  total_pages: number;
};

export type Report = {
  date: string;
  dynamics: ReportDynamics;
};

export type ReportItems = {
  [key: string]: Report;
};

export type ReportsFilters = {
  locales: string[];
  platforms: string[];
  pipeline?: string;
  offers: string[];
  payModes: string[];
};

export type CellData = {
  count: number;
  revenue: number;
  supposedRevenue: number;
  name: string;
};

export type ReportDynamics = {
  charged_delayed: CellData;
  charged_on_time: CellData;
  charged_total: CellData;
  inherited_active_next_month: CellData;
  inherited_planned_charge_subscriptions: CellData;
  inherited_planned_charges: CellData;
  inherited_planned_delayed_charges: CellData;
  inherited_support_unsubscribed: CellData;
  inherited_system_unsubscribed: CellData;
  inherited_user_unsubscribed: CellData;
  inherited_total_unsubscribed: CellData;
  inherited_without_planned_charge_subscriptions: CellData;
  initiated: CellData;
  initiated_active_next_month: CellData;
  initiated_planned_charges: CellData;
  initiated_support_unsubscribed: CellData;
  initiated_system_unsubscribed: CellData;
  initiated_user_unsubscribed: CellData;
  initiated_total_unsubscribed: CellData;
  not_charged: CellData;
  queued_for_next_month: CellData;
  refunded: CellData;
  revenue: CellData;
  tos_accepted: CellData;
  tos_declined: CellData;
  tos_pending: CellData;
  total_planned_charges: CellData;
  tos_auto_accept: CellData;
};

export type Reports = {
  isLoading: boolean;
  hasError: boolean;
  isLoadingMore: boolean;
  page: number;
  totalPages: number;
  items: ReportItems;
};

// SubscriptionReport part of global store
export interface SubscriptionReportState {
  isLoading: boolean;
  hasError: boolean;
  reports: Reports;
  formData: CommonDataResponse;
}

export const COMMON_DATA_LOAD = 'SUBSCRIPTIONS_COMMON_DATA_LOAD';
export const COMMON_DATA_SUCCESS = 'SUBSCRIPTIONS_COMMON_DATA_SUCCESS';
export const COMMON_DATA_ERROR = 'SUBSCRIPTIONS_COMMON_DATA_ERROR';

export const REPORTS_LOAD = 'SUBSCRIPTIONS_REPORTS_LOAD';
export const REPORTS_MORE_LOAD = 'SUBSCRIPTIONS_REPORTS_MORE_LOAD';
export const REPORTS_SUCCESS = 'SUBSCRIPTIONS_REPORTS_SUCCESS';
export const REPORTS_ERROR = 'SUBSCRIPTIONS_REPORTS_ERROR';

interface CommonDataLoad {
  type: typeof COMMON_DATA_LOAD;
}

interface CommonDataSuccess {
  type: typeof COMMON_DATA_SUCCESS;
  payload: CommonDataResponse;
}

interface CommonDataError {
  type: typeof COMMON_DATA_ERROR;
}

interface ReportsLoad {
  type: typeof REPORTS_LOAD;
}

interface ReportsMoreLoad {
  type: typeof REPORTS_MORE_LOAD;
}

interface ReportsSuccess {
  type: typeof REPORTS_SUCCESS;
  payload: ReportsResponse;
}

interface ReportsError {
  type: typeof REPORTS_ERROR;
}

export type SubscriptionReportActionTypes =
  | CommonDataLoad
  | CommonDataSuccess
  | CommonDataError
  | ReportsLoad
  | ReportsMoreLoad
  | ReportsSuccess
  | ReportsError;
