export enum ModalsTypes {
  download = 'download',
  upload = 'upload',
  retentionTemplateImport = 'retentionTemplateImport',
}

export type OpenedModals = {
  type: ModalsTypes;
  data?: {
    from?: string;
  };
};

export type ModalsState = {
  modals: OpenedModals[];
};

export const MODAL_ADD = 'MODAL_ADD';
export const MODAL_REMOVE = 'MODAL_REMOVE';

interface ModalAdd {
  type: typeof MODAL_ADD;
  payload: OpenedModals;
}

interface ModalRemove {
  type: typeof MODAL_REMOVE;
}

export type ModalActionTypes = ModalAdd | ModalRemove;
