import { compose } from 'redux';

declare global {
  interface Window {
    env: {
      API_URL: string;
      APP_URL: string;
      GOOGLE_CLIENT_ID: string;
      THEME: string;
      TRICKS_ENABLED: boolean;
    };
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const settings = {
  API_URL: process.env.REACT_APP_API_URL,
  APP_URL: process.env.REACT_APP_APP_URL,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  THEME: process.env.REACT_APP_THEME,
  TRICKS_ENABLED: process.env.REACT_APP_TRICKS_ENABLED === 'true' ?? false,
};

export default settings;
