export type Item = {
  id: number;
  paypal_product_id: string;
  type: string;
};

export type PurchaseItem = {
  id: number;
  frequency: number;
  item: Item;
  name: string;
  paying_type: number;
  title: string;
};

export interface PurchaseItemsState {
  isLoading: boolean;
  hasError: boolean;
  hasLoaded: boolean;
  purchaseItems?: PurchaseItem[];
}

export interface SupportPurchaseItemsState {
  isLoading: boolean;
  hasError: boolean;
  hasLoaded: boolean;
  supportPurchaseItems?: PurchaseItem[];
}

export interface PurchaseItemsStateType {
  purchaseItems?: PurchaseItemsState;
  supportPurchaseItems?: SupportPurchaseItemsState;
}

export const PURCHASE_ITEMS_LOAD = 'PURCHASE_ITEMS_LOAD';
export const PURCHASE_ITEMS_SUCCESS = 'PURCHASE_ITEMS_SUCCESS';
export const PURCHASE_ITEMS_ERROR = 'PURCHASE_ITEMS_ERROR';

export const SUPPORT_PURCHASE_ITEMS_LOAD = 'SUPPORT_PURCHASE_ITEMS_LOAD';
export const SUPPORT_PURCHASE_ITEMS_SUCCESS = 'SUPPORT_PURCHASE_ITEMS_SUCCESS';
export const SUPPORT_PURCHASE_ITEMS_ERROR = 'SUPPORT_PURCHASE_ITEMS_ERROR';

interface PurchaseItemsLoad {
  type: typeof PURCHASE_ITEMS_LOAD;
}

interface PurchaseItemsSuccess {
  type: typeof PURCHASE_ITEMS_SUCCESS;
  payload: PurchaseItem[];
}

interface PurchaseItemsError {
  type: typeof PURCHASE_ITEMS_ERROR;
}
interface SupportPurchaseItemsLoad {
  type: typeof SUPPORT_PURCHASE_ITEMS_LOAD;
}

interface SupportPurchaseItemsSuccess {
  type: typeof SUPPORT_PURCHASE_ITEMS_SUCCESS;
  payload: PurchaseItem[];
}

interface SupportPurchaseItemsError {
  type: typeof SUPPORT_PURCHASE_ITEMS_ERROR;
}

export type PurchaseItemsActionTypes =
  | PurchaseItemsLoad
  | PurchaseItemsSuccess
  | PurchaseItemsError
  | SupportPurchaseItemsLoad
  | SupportPurchaseItemsSuccess
  | SupportPurchaseItemsError;
