import * as t from './types';

export const addModal = (modal: t.OpenedModals): t.ModalActionTypes => ({
  type: t.MODAL_ADD,
  payload: modal,
});

export const removeModal = (): t.ModalActionTypes => ({
  type: t.MODAL_REMOVE,
});
