export enum OriginScope {
  pipe = 'pipe_transaction',
  user = 'user_origin',
}

export enum UserScope {
  userEmail = 'userEmail',
  userOriginDate = 'userOriginDate',
}

export enum TimeTo {
  today = 'today',
  days = 'days',
}

export enum SubOrigin {
  pipe = 'pipe',
  offer = 'offer',
}

export enum Platform {
  web,
  ios,
  ruby,
}

export interface FiltersState {
  from?: string;
  to?: string;
  user?: string; // actually email
  locales: string | string[];
  scope: OriginScope;
  pipeline?: string;
  period?: string;
  offers?: string[];
  platforms: Platform[];
  userScope?: UserScope;
  subOrigin: SubOrigin;
  timeTo?: TimeTo;
}

export interface FormDataItem {
  text: string;
  value: string;
}

export interface OfferFormData extends FormDataItem {
  group: string;
  value: string;
  text: string;
}

export interface FormData {
  offers: OfferFormData[];
}
export interface Summary {
  userCount: number;
  transactionCount: number;
  ltv: number;
  renewedCount: number;
  revenue: number;
  terminatedCount: number;
  unsubscribedCount: number;
  existingUserTransactionCount: number;
}

export enum ProductType {
  dietPlans = 'diet_plans',
  upsells = 'upsells',
}

export interface Product {
  name: string;
  revenue: number;
  transactionCount: number;
  type: ProductType;
  userCount: number;
}

export interface UserLifetimeValueResponse {
  products: Product[];
  summary: Summary;
}

// UserLifetimeValue part of global store
export interface UserLifetimeValueState {
  isLoading: boolean;
  hasError: boolean;
  data?: UserLifetimeValueResponse;
  filters: FiltersState;
  formData: FormData;
}

export const FORM_DATA_LOAD = 'USER_LIFETIME_VALUE_FORM_DATA_LOAD';
export const FORM_DATA_SUCCESS = 'USER_LIFETIME_VALUE_FORM_DATA_SUCCESS';
export const FORM_DATA_ERROR = 'USER_LIFETIME_VALUE_FORM_DATA_ERROR';

export const DATA_LOAD = 'USER_LIFETIME_VALUE_DATA_LOAD';
export const DATA_SUCCESS = 'USER_LIFETIME_VALUE_DATA_SUCCESS';
export const DATA_ERROR = 'USER_LIFETIME_VALUE_DATA_ERROR';

export const CLEAR_FILTERS = 'USER_LIFETIME_VALUE_CLEAR_FILTERS';

interface FormDataLoad {
  type: typeof FORM_DATA_LOAD;
}

interface FormDataSuccess {
  type: typeof FORM_DATA_SUCCESS;
  payload: FormData;
}

interface FormDataError {
  type: typeof FORM_DATA_ERROR;
}

interface DataLoad {
  type: typeof DATA_LOAD;
}

interface DataSuccess {
  type: typeof DATA_SUCCESS;
  payload: UserLifetimeValueResponse;
  filters: FiltersState;
}

interface DataError {
  type: typeof DATA_ERROR;
}

interface ClearFilters {
  type: typeof CLEAR_FILTERS;
}

export type UserLifetimeValueActionTypes =
  | FormDataLoad
  | FormDataSuccess
  | FormDataError
  | DataLoad
  | DataSuccess
  | DataError
  | ClearFilters;
