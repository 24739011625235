import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';

import FlashMessages from 'components/FlashMessages/FlashMessages';
import Modals from 'components/Modals/Modals';
import history from 'helpers/history';
import { isAuthenticated } from 'helpers/auth';
import { AppState } from 'store';
import { loadCommonData } from 'store/common/actions';
import Routes from './routes';

// Styles
import 'semantic-ui-css/semantic.min.css';
import 'react-chat-elements/dist/main.css';
import './index.css';

const App: React.FC = () => {
  const { hasLoaded, hasError, isLoading } = useSelector(
    (state: AppState) => state.commonData
  );
  const dispatch = useDispatch();

  if (isAuthenticated() && !hasLoaded && !hasError && !isLoading) {
    dispatch(loadCommonData());
  }

  return (
    <Router history={history}>
      <Routes />
      <Modals />
      <FlashMessages />
    </Router>
  );
};

export default App;
