export enum FlashMessageType {
  success = 'success',
  error = 'error',
}

export interface FlashMessage {
  id?: number;
  header: string;
  content: string;
  expiryMs?: number;
  messageType: string;
}

export interface FlashMessagesState {
  list: FlashMessage[];
}

export const FLASH_MESSAGE_ADD = 'FLASH_MESSAGE_ADD';
export const FLASH_MESSAGE_REMOVE = 'FLASH_MESSAGE_REMOVE';

interface FlashMessageAdd {
  type: typeof FLASH_MESSAGE_ADD;
  message: FlashMessage;
}

interface FlashMessageRemove {
  type: typeof FLASH_MESSAGE_REMOVE;
  id?: number;
}

export type FlashMessagesActionTypes = FlashMessageAdd | FlashMessageRemove;
