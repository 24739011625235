/* eslint-disable @typescript-eslint/camelcase */
import * as t from './types';

export const initialState: t.TestimonialState = {
  hasLoaded: true,
  isLoading: false,
};

const testimonialReducer = (
  state = initialState,
  action: t.TestimonialActionTypes
): t.TestimonialState => {
  switch (action.type) {
    case t.TESTIMONIAL_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        submitFilters: action.payload,
      };
    case t.TESTIMONIAL_LIST_SUCCESS:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        error: undefined,
        list: action.payload,
      };
    case t.TESTIMONIAL_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default testimonialReducer;
