import { PaginationMetaData, PaginationPageLinks } from 'types/pagination';
import settings from '../../helpers/settings';

export const urlBase = `${settings.API_URL}/admin/audit-events`;

export enum SOURCE_TYPE {
  user = 'User',
}

export interface AuditEvent {
  id: number;
  type: string;
  source_id: number;
  source_type: string;
  created_at: string;
}

export type UsersAuditEvent = {
  data: AuditEvent[] | null;
  links: PaginationPageLinks | null;
  meta: PaginationMetaData | null;
};

export type AuditEventState = {
  isLoading: boolean;
  hasError: boolean;
  userAuditEvent: UsersAuditEvent | null;
};

export const USER_AUDIT_EVENT_LOAD = 'USER_AUDIT_EVENT_LOAD';
export const USER_AUDIT_EVENT_SUCCESS = 'USER_AUDIT_EVENT_SUCCESS';
export const USER_AUDIT_EVENT_FAILED = 'USER_AUDIT_EVENT_FAILED';

interface UserAuditEventLoadAction {
  type: typeof USER_AUDIT_EVENT_LOAD;
}

interface UserAuditEventSuccessAction {
  type: typeof USER_AUDIT_EVENT_SUCCESS;
  payload: UsersAuditEvent;
}

interface UserAuditEventFailedAction {
  type: typeof USER_AUDIT_EVENT_FAILED;
}

export type AuditEventActionTypes =
  | UserAuditEventLoadAction
  | UserAuditEventSuccessAction
  | UserAuditEventFailedAction;
