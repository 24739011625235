/* eslint-disable @typescript-eslint/camelcase */
import * as t from './types';

const initialState: t.UsersState = {
  isLoading: false,
  hasError: false,
  panelInfo: {
    unread: null,
    translationQueue: null,
  },
  conversations: {
    isLoading: false,
    items: [],
    currentPage: 1,
    totalCount: 0,
  },
  activeChat: {
    isLoading: false,
    messages: {
      items: [] as Array<t.ChatMessage>,
      loadMore: false,
      userId: null,
      isLoading: false,
    },
    fieldUpdateStatus: {},
    isDeleteLoading: false,
  },
  cableEndpoint: {
    loaded: false,
    action: '',
    auth_endpoint: '',
    app_key: '',
    cluster: '',
  },
};

const userReducer = (
  state = initialState,
  action: t.UserActionTypes
): t.UsersState => {
  switch (action.type) {
    case t.INITIAL_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case t.CONVERSATION_LIST_LOAD:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          isLoading: true,
        },
      };

    case t.CHAT_DATA_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          isLoading: true,
        },
      };

    case t.CHAT_MESSAGES_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: {
            ...state.activeChat.messages,
            isLoading: true,
          },
        },
      };

    case t.INITIAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        panelInfo: action.payload.panelInfo,
        conversations: {
          ...state.conversations,
          ...action.payload.conversations,
        },
        cableEndpoint: action.payload.cableEndpoint,
      };

    case t.CONVERSATION_LIST_SUCCESS:
      return {
        ...state,
        hasError: false,
        conversations: {
          ...action.payload,
          isLoading: false,
        },
      };

    case t.CHAT_DATA_SUCCESS:
      return {
        ...state,
        hasError: false,
        activeChat: {
          ...action.payload,
          isLoading: false,
        },
      };

    case t.CHAT_MESSAGES_CLEAR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: {
            ...state.activeChat.messages,
            items: [],
          },
        },
      };

    case t.CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: {
            ...action.payload,
            isLoading: false,
            items: [
              ...state.activeChat.messages.items,
              ...action.payload.items,
            ],
          },
        },
      };

    case t.MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: {
            ...state.activeChat.messages,
            items: [action.payload, ...state.activeChat.messages.items],
          },
        },
      };

    case t.DELETE_USER_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          isDeleteLoading: true,
        },
      };

    case t.DELETE_USER_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          isDeleteLoading: false,
        },
        conversations: {
          ...state.conversations,
          items: state.conversations.items.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case t.DELETE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        activeChat: {
          ...state.activeChat,
          isDeleteLoading: false,
        },
      };

    case t.MARK_USER_AS_DONE:
      return {
        ...state,
        activeChat: initialState.activeChat,
        conversations: {
          ...state.conversations,
          items: state.conversations.items.filter(
            (c) => c.id !== action.payload
          ),
        },
      };

    case t.USER_FIELD_UPDATE_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          fieldUpdateStatus: {
            ...state.activeChat.fieldUpdateStatus,
            [action.payload]: true,
          },
        },
      };

    case t.USER_FIELD_UPDATE_SUCCESS:
    case t.USER_FIELD_UPDATE_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          fieldUpdateStatus: {
            ...state.activeChat.fieldUpdateStatus,
            [action.payload]: false,
          },
        },
      };

    case t.INITIAL_DATA_ERROR:
    case t.CONVERSATION_LIST_ERROR:
    case t.CHAT_DATA_ERROR:
    case t.MARK_USER_AS_DONE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.PREMIUM_ADD_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            premiumAdd: {
              isLoading: true,
              hasError: null,
            },
          },
        },
      };
    case t.PREMIUM_ADD_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            premiumAdd: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.PREMIUM_ADD_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            premiumAdd: {
              isLoading: false,
              hasError: false,
            },
          },
        },
      };
    case t.PREMIUM_ADD_CLEAR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            premiumAdd: {
              isLoading: false,
              hasError: null,
            },
          },
        },
      };
    case t.TICKETS_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            tickets: {
              isLoading: true,
              hasError: false,
            },
          },
        },
      };
    case t.TICKETS_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            tickets: {
              isLoading: false,
              hasError: false,
              tickets: action.payload,
            },
          },
        },
      };
    case t.TICKETS_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            tickets: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.TERMINATE_TICKET_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateTicket: {
              isLoading: true,
              hasError: false,
            },
          },
        },
      };
    case t.TERMINATE_TICKET_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateTicket: {
              isLoading: false,
              hasError: false,
            },
          },
        },
      };
    case t.TERMINATE_TICKET_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateTicket: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.TERMINATE_TICKET_RESET:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateTicket: undefined,
          },
        },
      };
    case t.STORE_TICKET_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            storeTicket: {
              isLoading: true,
              hasError: false,
            },
          },
        },
      };
    case t.STORE_TICKET_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            storeTicket: {
              isLoading: false,
              hasError: false,
            },
          },
        },
      };
    case t.STORE_TICKET_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            storeTicket: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.STORE_TICKET_RESET:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            storeTicket: undefined,
          },
        },
      };
    case t.SUBSCRIPTIONS_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            subscriptions: {
              isLoading: true,
              hasError: false,
            },
          },
        },
      };
    case t.SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            subscriptions: {
              isLoading: false,
              hasError: false,
              subscriptions: action.payload,
            },
          },
        },
      };
    case t.SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            subscriptions: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.TERMINATE_SUBSCRIPTION_LOAD:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateSubscription: {
              isLoading: true,
              hasError: false,
            },
          },
        },
      };
    case t.TERMINATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateSubscription: {
              isLoading: false,
              hasError: false,
            },
          },
        },
      };
    case t.TERMINATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateSubscription: {
              isLoading: false,
              hasError: true,
            },
          },
        },
      };
    case t.TERMINATE_SUBSCRIPTION_RESET:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          userInfo: {
            ...(state.activeChat.userInfo as t.UserInfo),
            terminateSubscription: undefined,
          },
        },
      };
    default: {
      return state;
    }
  }
};

export default userReducer;
