import { LocalizedValues, MediaType } from 'types';

export interface ExercisesListItem {
  id: string;
  title: string;
  code: string;
  updated: string;
}

interface ExerciseDataCommon {
  updated: string;
  code: string;
  group: string;
  titles: LocalizedValues<string>;
  descriptions: LocalizedValues<string>;
  repetitions: string;
  calories: string;
  repetitionsMin: string;
  repetitionsMax: string;
  repetitionsStep: string;
  durationMin: string;
  durationMax: string;
  durationStep: string;
  defaultVideo: string;
  videos: LocalizedValues<MediaType> | LocalizedValues<string>;
}

export interface ExerciseData extends ExerciseDataCommon {
  videos: LocalizedValues<MediaType>;
  images: LocalizedValues<MediaType>;
}

export interface ExerciseDataSubmit extends ExerciseDataCommon {
  videos: LocalizedValues<string>;
}

export interface ExerciseEditState {
  isLoading: boolean;
  hasError: boolean;
  exercise: ExerciseData;
}

export interface ExerciseSubmitState {
  isLoading: boolean;
  error?: Error;
}

// Exercises part of global store
export interface ExerciseState {
  isLoading: boolean;
  hasError: boolean;
  listItems: Array<ExercisesListItem>;
  edit: ExerciseEditState;
  submit: ExerciseSubmitState;
}

export const EXERCISE_LIST_LOAD = 'EXERCISE_LIST_LOAD';
export const EXERCISE_LIST_SUCCESS = 'EXERCISE_LIST_SUCCESS';
export const EXERCISE_LIST_ERROR = 'EXERCISE_LIST_ERROR';

export const EXERCISE_EDIT_DATA_LOAD = 'EXERCISE_EDIT_DATA_LOAD';
export const EXERCISE_EDIT_DATA_SUCCESS = 'EXERCISE_EDIT_DATA_SUCCESS';
export const EXERCISE_EDIT_DATA_ERROR = 'EXERCISE_EDIT_DATA_ERROR';

export const EXERCISE_UPDATE_LOAD = 'EXERCISE_UPDATE_LOAD';
export const EXERCISE_UPDATE_SUCCESS = 'EXERCISE_UPDATE_SUCCESS';
export const EXERCISE_UPDATE_ERROR = 'EXERCISE_UPDATE_ERROR';

export const EXERCISE_NEW_LOAD = 'EXERCISE_NEW_LOAD';
export const EXERCISE_NEW_SUCCESS = 'EXERCISE_NEW_SUCCESS';
export const EXERCISE_NEW_ERROR = 'EXERCISE_NEW_ERROR';

interface ExerciseListLoadAction {
  type: typeof EXERCISE_LIST_LOAD;
}

interface ExerciseListSuccess {
  type: typeof EXERCISE_LIST_SUCCESS;
  payload: Array<ExercisesListItem>;
}

interface ExerciseListError {
  type: typeof EXERCISE_LIST_ERROR;
}

interface ExerciseEditDataLoadAction {
  type: typeof EXERCISE_EDIT_DATA_LOAD;
}

interface ExerciseEditDataSuccess {
  type: typeof EXERCISE_EDIT_DATA_SUCCESS;
  payload: ExerciseData;
}

interface ExerciseEditDataError {
  type: typeof EXERCISE_EDIT_DATA_ERROR;
}

interface ExerciseUpdateLoadAction {
  type: typeof EXERCISE_UPDATE_LOAD;
}

interface ExerciseUpdateSuccess {
  type: typeof EXERCISE_UPDATE_SUCCESS;
}

interface ExerciseUpdateError {
  type: typeof EXERCISE_UPDATE_ERROR;
  payload: Error;
}

interface ExerciseNewLoadAction {
  type: typeof EXERCISE_NEW_LOAD;
}

interface ExerciseNewSuccess {
  type: typeof EXERCISE_NEW_SUCCESS;
}

interface ExerciseNewError {
  type: typeof EXERCISE_NEW_ERROR;
  payload: Error;
}

export type ExerciseActionTypes =
  | ExerciseListLoadAction
  | ExerciseListSuccess
  | ExerciseListError
  | ExerciseEditDataLoadAction
  | ExerciseEditDataSuccess
  | ExerciseEditDataError
  | ExerciseUpdateLoadAction
  | ExerciseUpdateSuccess
  | ExerciseUpdateError
  | ExerciseNewLoadAction
  | ExerciseNewSuccess
  | ExerciseNewError;
