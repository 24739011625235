import * as t from './types';

const initialState: t.TelemarketingState = {
  hasError: false,
  isLoading: false,
  users: {
    isLoading: false,
    currentPage: 1,
    totalPages: 1,
    items: [],
    totalCount: 0,
  },
  filters: {
    createdAt: {
      startDate: '',
      endDate: '',
    },
    lastOnline: {
      startDate: '',
      endDate: '',
    },
  },
};

const telemarketingReducer = (
  state = initialState,
  action: t.TelemarketingActionTypes
): t.TelemarketingState => {
  switch (action.type) {
    case t.TELEMARKETING_USERS_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case t.TELEMARKETING_USERS_LOAD:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };

    case t.TELEMARKETING_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...action.payload,
          isLoading: false,
        },
      };

    case t.RESET_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };

    case t.DIAL_USER_SUCCESS: {
      const { clientId, adminUser } = action.payload;

      // updates specific row with new lastCalled data
      const updatedItems = state.users.items.reduce((accumulator, item) => {
        if (item.id === clientId) {
          accumulator.push({ ...item, lastCalled: adminUser });
          return accumulator;
        }
        accumulator.push(item);
        return accumulator;
      }, [] as Array<t.TelemarketingUser>);

      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          items: [...updatedItems],
        },
      };
    }

    default:
      return state;
  }
};

export default telemarketingReducer;
