import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import PrivateRoute from './PrivateRoute';

const Programs = React.lazy(() => import('./Programs'));
const Login = React.lazy(() => import('./Login'));
const Offers = React.lazy(() => import('./Offers/Offers'));
const OfferCreate = React.lazy(() => import('./OfferCreate'));
const OfferEdit = React.lazy(() => import('./OfferEdit'));
const SubscriptionReport = React.lazy(() => import('./SubscriptionReport'));
const Customers = React.lazy(() => import('./Customers'));
const Telemarketing = React.lazy(() => import('./Telemarketing'));
const UserLifetimeValue = React.lazy(() => import('./UserLifetimeValue'));
const PaymentProviderRules = React.lazy(() => import('./PaymentProviderRules'));

const Routes = () => {
  return (
    <div>
      <Suspense fallback={<Loader active content="Loading" />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/">
            <Redirect to="/offers" />
          </PrivateRoute>
          <PrivateRoute exact path="/offers" component={Offers} />
          <PrivateRoute path="/offers/:offerId/edit" component={OfferEdit} />
          <PrivateRoute exact path="/offers/new" component={OfferCreate} />
          <PrivateRoute
            exact
            path="/subscription_dynamics_and_income"
            component={SubscriptionReport}
          />
          <PrivateRoute path="/programs" component={Programs} />
          <PrivateRoute path="/customers" component={Customers} />
          <PrivateRoute exact path="/telemarketing" component={Telemarketing} />
          <PrivateRoute
            exact
            path="/user_lifetime_value"
            component={UserLifetimeValue}
          />
          <PrivateRoute
            exact
            path="/payment_provider_rules"
            component={PaymentProviderRules}
          />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
