import * as t from './types';

const initialState: t.FitnessProgramsState = {
  isLoading: false,
  hasError: false,
  listItems: [],
  edit: {
    isLoading: false,
    hasError: false,
    program: {
      code: '',
      state: '',
      titles: {},
      workouts: [],
      updated: '',
    },
  },
  formData: {
    states: [],
    workouts: [],
  },
  submit: {
    isLoading: false,
    hasError: false,
  },
};

const fitnessProgramsReducer = (
  state = initialState,
  action: t.FitnessActionTypes
): t.FitnessProgramsState => {
  switch (action.type) {
    case t.FITNESS_LIST_LOAD:
    case t.FITNESS_FORM_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.FITNESS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listItems: action.payload,
      };
    case t.FITNESS_LIST_ERROR:
    case t.FITNESS_FORM_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.FITNESS_FORM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formData: action.payload,
      };
    case t.FITNESS_EDIT_DATA_LOAD:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };
    case t.FITNESS_EDIT_DATA_SUCCESS:
      return {
        ...state,
        edit: {
          isLoading: false,
          hasError: false,
          program: action.payload,
        },
      };
    case t.FITNESS_EDIT_DATA_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          hasError: true,
        },
      };
    case t.FITNESS_NEW_PROGRAM_LOAD:
    case t.FITNESS_UPDATE_PROGRAM_LOAD:
      return {
        ...state,
        submit: {
          isLoading: true,
          hasError: false,
        },
      };
    case t.FITNESS_NEW_PROGRAM_SUCCESS:
    case t.FITNESS_UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: false,
        },
      };
    case t.FITNESS_NEW_PROGRAM_ERROR:
    case t.FITNESS_UPDATE_PROGRAM_ERROR:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: true,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default fitnessProgramsReducer;
