import { FlagNameValues } from 'semantic-ui-react';
import { LocalizedValues, MediaType, DimensionsRange } from 'types';
import { capitalize, desnake } from './formattingHelper';

type LocaleProps = {
  [key: string]: string;
};

type FlagOptions = {
  key: string | number;
  text: string;
  value: string | number;
  flag: string | number;
};

export const mapStringOptions = (array: (string | number)[]) =>
  array.map((element) => ({
    key: element,
    text: element,
    value: element,
  }));

/**
 * For array of keys, formats array for select options with human-readable text
 */
export const mapKeyStringOptions = (array: (string | number)[]) =>
  array.map((element) => ({
    key: element,
    text: capitalize(desnake(element.toString())),
    value: element,
  }));

export const localeText: LocaleProps = {
  aa: 'Afar',
  ab: 'Abkhazian',
  ae: 'Avestan',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'Amharic',
  an: 'Aragonese',
  ar: 'Arabic',
  as: 'Assamese',
  av: 'Avaric',
  ay: 'Aymara',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari languages',
  bi: 'Bislama',
  bm: 'Bambara',
  bn: 'Bengali',
  bo: 'Tibetan',
  br: 'Breton',
  bs: 'Bosnian',
  ca: 'Catalan',
  ce: 'Chechen',
  ch: 'Chamorro',
  co: 'Corsican',
  cr: 'Cree',
  cs: 'Czech',
  cu: 'Church Slavic',
  cv: 'Chuvash',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dv: 'Maldivian',
  dz: 'Dzongkha',
  ee: 'Ewe',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  ff: 'Fulah',
  fi: 'Finnish',
  fj: 'Fijian',
  fo: 'Faroese',
  fr: 'French',
  fy: 'Western Frisian',
  ga: 'Irish',
  gd: 'Gaelic',
  gl: 'Galician',
  gn: 'Guarani',
  gu: 'Gujarati',
  gv: 'Manx',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hr: 'Croatian',
  ht: 'Haitian',
  hu: 'Hungarian',
  hy: 'Armenian',
  hz: 'Herero',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Sichuan Yi',
  ik: 'Inupiaq',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  iu: 'Inuktitut',
  ja: 'Japanese',
  jv: 'Javanese',
  ka: 'Georgian',
  kg: 'Kongo',
  ki: 'Kikuyu',
  kj: 'Kuanyama',
  kk: 'Kazakh',
  kl: 'Kalaallisut',
  km: 'Central Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  kv: 'Komi',
  kw: 'Cornish',
  ky: 'Kirghiz',
  la: 'Latin',
  lb: 'Luxembourgish',
  lg: 'Ganda',
  li: 'Limburgan',
  ln: 'Lingala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lv: 'Latvian',
  mg: 'Malagasy',
  mh: 'Marshallese',
  mi: 'Maori',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mr: 'Marathi',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauru',
  nb: 'Norwegian',
  nd: 'North Ndebele',
  ne: 'Nepali',
  ng: 'Ndonga',
  nl: 'Dutch',
  nn: 'Norwegian',
  no: 'Norwegian',
  nr: 'South Ndebele',
  nv: 'Navajo',
  ny: 'Chichewa',
  oc: 'Occitan',
  oj: 'Ojibwa',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetic',
  pa: 'Panjabi',
  pi: 'Pali',
  pl: 'Polish',
  ps: 'Pushto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansh',
  rn: 'Rundi',
  ro: 'Romanian',
  ru: 'Russian',
  rw: 'Kinyarwanda',
  sa: 'Sanskrit',
  sc: 'Sardinian',
  sd: 'Sindhi',
  se: 'Northern Sami',
  sg: 'Sango',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  sq: 'Albanian',
  sr: 'Serbian',
  ss: 'Swati',
  st: 'Sotho, Southern',
  su: 'Sundanese',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ty: 'Tahitian',
  ug: 'Uighur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wa: 'Walloon',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang',
  zh: 'Chinese',
  zu: 'Zulu',
};

function getValueByKey(object: LocaleProps, value: string | number) {
  return object[value];
}

function sortLocalesAlphabetically(a: FlagOptions, b: FlagOptions) {
  return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
}

function convertLocaleToCountryCode(value: string | number) {
  switch (value) {
    case 'en':
      return 'gb';
    case 'et':
      return 'ee';
    case 'el':
      return 'gr';
    case 'da':
      return 'dk';
    case 'ka':
      return 'ge';
    case 'uk':
      return 'ua';
    default:
      return value;
  }
}

export const mapStringOptionsFlag = (array: (string | number)[]) => {
  const optionsFlagObject = array.map((element) => ({
    key: element,
    text: getValueByKey(localeText, element) ?? '-',
    value: element,
    flag: convertLocaleToCountryCode(element),
  }));
  optionsFlagObject.sort(sortLocalesAlphabetically);

  const enIndex = optionsFlagObject.findIndex((value) => value.key === 'en');
  if (enIndex === 0) {
    return optionsFlagObject;
  }
  const getEnLocale = optionsFlagObject.splice(enIndex, 1)[0];
  optionsFlagObject.splice(0, 0, getEnLocale);

  return optionsFlagObject;
};

type EnumType = {
  [s: number]: string;
};

export const mapEnumToOptions = (enumerable: EnumType) => {
  return (
    Object.values(enumerable)
      .filter((val) => typeof val === 'number')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((val: any) => ({
        key: val,
        value: val,
        text: enumerable[val],
      }))
  );
};

export const formatMedia = (media: LocalizedValues<MediaType>) => {
  const returnObject: LocalizedValues<string> = {};
  Object.entries(media).forEach(([key, value]) => {
    if (!value || value.fileName === '') return;

    returnObject[key as FlagNameValues] = value.fileName;
  });
  return returnObject;
};

export const localMediaLoader = (media: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string);
      },
      false
    );
    reader.readAsDataURL(media);
  });

export const validateDimensions = (
  media: File,
  validDimensions: DimensionsRange
) => {
  return new Promise((resolve) => {
    const { minWidth, maxWidth, minHeight, maxHeight } = validDimensions;
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (
          img.width > minWidth &&
          img.width < maxWidth &&
          img.height > minHeight &&
          img.height < maxHeight
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(media);
  });
};

export const fileExtension = (filename: string): string => {
  return filename.split('.').pop() || '';
};

export const sortedStringOptions = (stringArray: string[]) => {
  const sortedArray = stringArray.sort((a, b) => a.localeCompare(b));

  return mapStringOptions(sortedArray);
};

export const validateArrayHasItems = (stringArray: string[]) =>
  Boolean(stringArray.length);
