/* eslint-disable @typescript-eslint/camelcase */
import * as t from './types';

const initialState: t.DisputeState = {
  isLoading: false,
  hasError: false,
};

const disputesReducer = (
  state = initialState,
  action: t.DisputesActionType
): t.DisputeState => {
  switch (action.type) {
    case t.DISPUTES_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.DISPUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        dispute: action.payload,
      };
    case t.DISPUTES_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default disputesReducer;
