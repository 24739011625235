import { PaginationMetaData } from 'types/pagination';

// PAGE PATH
export const PAGE_PATH = '/customers/retention_templates';

// Payment Provider Rule list
export const RETENTION_TEMPLATE_LIST_LOAD = 'RETENTION_TEMPLATE_LIST_LOAD';
export const RETENTION_TEMPLATE_LIST_SUCCESS = 'RETENTION_TEMPLATE_SUCCESS';
export const RETENTION_TEMPLATE_LIST_ERROR = 'RETENTION_TEMPLATE_ERROR';

// Payment Provider Rule current item
export const RETENTION_TEMPLATE_CURRENT_LOAD =
  'RETENTION_TEMPLATE_CURRENT_LOAD';
export const RETENTION_TEMPLATE_CURRENT_SUCCESS =
  'RETENTION_TEMPLATE_CURRENT_SUCCESS';
export const RETENTION_TEMPLATE_CURRENT_ERROR =
  'RETENTION_TEMPLATE_CURRENT_ERROR';

// Retention Templates current list item save
export const RETENTION_TEMPLATE_CURRENT_SAVE =
  'RETENTION_TEMPLATE_CURRENT_SAVE';
export const RETENTION_TEMPLATE_CURRENT_SAVE_SUCCESS =
  'RETENTION_TEMPLATE_CURRENT_SAVE_SUCCESS';
export const RETENTION_TEMPLATE_CURRENT_SAVE_ERROR =
  'RETENTION_TEMPLATE_CURRENT_SAVE_ERROR';

// Retention Templates execute action
export const RETENTION_TEMPLATE_EXECUTE = 'RETENTION_TEMPLATE_EXECUTE';
export const RETENTION_TEMPLATE_EXECUTE_SUCCESS =
  'RETENTION_TEMPLATE_EXECUTE_SUCCESS';
export const RETENTION_TEMPLATE_EXECUTE_ERROR =
  'RETENTION_TEMPLATE_EXECUTE_ERROR';
export const RETENTION_TEMPLATE_EXECUTE_RESET =
  'RETENTION_TEMPLATE_EXECUTE_RESET';

// Retention Template Actions current list item save
export const RETENTION_TEMPLATE_ACTION_SAVE = 'RETENTION_TEMPLATE_ACTION_SAVE';
export const RETENTION_TEMPLATE_ACTION_SAVE_SUCCESS =
  'RETENTION_TEMPLATE_ACTION_SAVE_SUCCESS';
export const RETENTION_TEMPLATE_ACTION_SAVE_ERROR =
  'RETENTION_TEMPLATE_ACTION_SAVE_ERROR';

// Retention Templates current item
export const RETENTION_TEMPLATE_GENERATED_LOAD =
  'RETENTION_TEMPLATE_GENERATED_LOAD';
export const RETENTION_TEMPLATE_GENERATED_SUCCESS =
  'RETENTION_TEMPLATE_GENERATED_SUCCESS';
export const DISPUTE_TEMPLATE_GENERATED_SUCCESS =
  'DISPUTE_TEMPLATE_GENERATED_SUCCESS';
export const RETENTION_TEMPLATE_GENERATED_ERROR =
  'RETENTION_TEMPLATE_GENERATED_ERROR';

export interface RetentionTemplateResponse {
  data: RetentionTemplateListItem[];
  meta: PaginationMetaData;
}

interface RetentionTemplateListLoadAction {
  type: typeof RETENTION_TEMPLATE_LIST_LOAD;
}

interface RetentionTemplateListSuccess {
  type: typeof RETENTION_TEMPLATE_LIST_SUCCESS;
  payload: RetentionTemplateResponse;
}

interface RetentionTemplateListError {
  type: typeof RETENTION_TEMPLATE_LIST_ERROR;
}

interface RetentionTemplateCurrentLoadAction {
  type: typeof RETENTION_TEMPLATE_CURRENT_LOAD;
}

interface RetentionTemplateCurrentSuccess {
  type: typeof RETENTION_TEMPLATE_CURRENT_SUCCESS;
  data: RetentionTemplateCurrentResponse;
}

interface RetentionTemplateCurrentError {
  type: typeof RETENTION_TEMPLATE_CURRENT_ERROR;
}

interface RetentionTemplateCurrentSaveAction {
  type: typeof RETENTION_TEMPLATE_CURRENT_SAVE;
}

interface RetentionTemplateCurrentSaveSuccess {
  type: typeof RETENTION_TEMPLATE_CURRENT_SAVE_SUCCESS;
  data: RetentionTemplateCurrentResponse;
}

interface RetentionTemplateCurrentSaveError {
  type: typeof RETENTION_TEMPLATE_CURRENT_SAVE_ERROR;
  data: Error;
}

interface RetentionTemplateGeneratedLoadAction {
  type: typeof RETENTION_TEMPLATE_GENERATED_LOAD;
}

interface RetentionTemplateGeneratedSuccess {
  type: typeof RETENTION_TEMPLATE_GENERATED_SUCCESS;
  data: RetentionTemplateGeneratedResponse;
}

interface DisputeTemplateGeneratedSuccess {
  type: typeof DISPUTE_TEMPLATE_GENERATED_SUCCESS;
  data: RetentionTemplateGeneratedResponse;
}

interface RetentionTemplateGeneratedError {
  type: typeof RETENTION_TEMPLATE_GENERATED_ERROR;
}

interface RetentionTemplateActionSaveAction {
  type: typeof RETENTION_TEMPLATE_ACTION_SAVE;
}

interface RetentionTemplateActionSaveSuccess {
  type: typeof RETENTION_TEMPLATE_ACTION_SAVE_SUCCESS;
  data: RetentionTemplateActionResponse;
}

interface RetentionTemplateActionSaveError {
  type: typeof RETENTION_TEMPLATE_ACTION_SAVE_ERROR;
  data: Error;
}

interface RetentionTemplateExecute {
  type: typeof RETENTION_TEMPLATE_EXECUTE;
}

interface RetentionTemplateExecuteSuccess {
  type: typeof RETENTION_TEMPLATE_EXECUTE_SUCCESS;
  data: RetentionTemplateExecuteResponse;
}

interface RetentionTemplateExecuteError {
  type: typeof RETENTION_TEMPLATE_EXECUTE_ERROR;
}

interface RetentionTemplateExecuteReset {
  type: typeof RETENTION_TEMPLATE_EXECUTE_RESET;
}

export type RetentionTemplateFilters = {
  page: number | string;
  name?: string;
  status?: 'active' | 'draft' | 'archived';
  type?: 'retention' | 'dispute';
};

export interface RetentionTemplateListItem {
  id: number | string;
  name: string;
  status: string;
  type: string;
  created_at: string;
  updated_at: string;
  translations: RetentionTemplateFormTranslation[];
  actions: RetentionTemplateActionForm[];
  export_url: string;
}

export interface RetentionTemplateFormTranslation {
  locale: string;
  content: string;
}

export interface RetentionTemplateForm {
  name: string;
  status: string;
  type: string;
  translations: RetentionTemplateFormTranslation[];
}

export interface RetentionTemplateCurrentResponse {
  data: RetentionTemplateListItem;
}

export interface RetentionTemplateGeneratedResponse {
  data: string;
  error?: Error;
}

export interface RetentionTemplateActionResponse {
  data: RetentionTemplateActionForm;
}

export interface RetentionTemplateActionForm {
  id?: number;
  action: string;
  configuration: {
    [key: string]: string | number | boolean;
  };
  sequence: number;
  stop_on_fail: boolean;
}

export interface RetentionTemplateExecuteListItem {
  id: number;
  retention_template_action_id: number;
  status: string;
  incoming_params: object;
  results: string | object;
}

export interface RetentionTemplateExecuteResponse {
  data: RetentionTemplateExecuteListItem[];
}

export interface RetentionTemplateState {
  isLoading: boolean;
  hasError: boolean;
  listItems: RetentionTemplateResponse;
  current: RetentionTemplateCurrentResponse;
  actionState: {
    retentionTemplateSave: {
      isLoading: boolean;
      hasUpdated: boolean;
      error?: Error;
    };
    retentionTemplateActionSave: {
      isLoading: boolean;
      hasUpdated: boolean;
      error?: Error;
    };
  };
  generated: RetentionTemplateGeneratedResponse;
  generatedInModal: RetentionTemplateGeneratedResponse;
  execute: {
    isLoading: boolean;
    data: RetentionTemplateExecuteListItem[];
  };
}

export type RetentionTemplateActionTypes =
  | RetentionTemplateListLoadAction
  | RetentionTemplateListSuccess
  | RetentionTemplateListError
  | RetentionTemplateCurrentLoadAction
  | RetentionTemplateCurrentSuccess
  | RetentionTemplateCurrentError
  | RetentionTemplateCurrentSaveAction
  | RetentionTemplateCurrentSaveError
  | RetentionTemplateCurrentSaveSuccess
  | RetentionTemplateGeneratedLoadAction
  | RetentionTemplateGeneratedSuccess
  | RetentionTemplateGeneratedError
  | RetentionTemplateActionSaveAction
  | RetentionTemplateActionSaveSuccess
  | RetentionTemplateActionSaveError
  | RetentionTemplateExecute
  | RetentionTemplateExecuteSuccess
  | RetentionTemplateExecuteError
  | RetentionTemplateExecuteReset
  | DisputeTemplateGeneratedSuccess;
