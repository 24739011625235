import { LocalizedValues, MediaType } from 'types';

export interface TricksListItem {
  id: string;
  title: string;
  code: string;
  updated: string;
}

export interface FormData {
  benefits: string[];
}

export interface TrickData {
  updated: string;
  code: string;
  state: string;
  titles: LocalizedValues<string>;
  recipes: LocalizedValues<string>;
  images: LocalizedValues<MediaType>;
  goals: string[];
  benefits: string[];
}

export interface TrickPostData {
  updated: string;
  code: string;
  state: string;
  titles: LocalizedValues<string>;
  recipes: LocalizedValues<string>;
  images: LocalizedValues<string>;
  goals: string[];
  benefits: string[];
}

export interface TrickEditState {
  isLoading: boolean;
  hasError: boolean;
  trick: TrickData;
}

export interface TrickSubmitState {
  isLoading: boolean;
  hasError: boolean;
  error?: Error;
}

// Tricks part of global store
export interface TricksState {
  isLoading: boolean;
  hasError: boolean;
  listItems: TricksListItem[];
  edit: TrickEditState;
  formData: FormData;
  submit: TrickSubmitState;
}

export const TRICK_LIST_LOAD = 'TRICK_LIST_LOAD';
export const TRICK_LIST_SUCCESS = 'TRICK_LIST_SUCCESS';
export const TRICK_LIST_ERROR = 'TRICK_LIST_ERROR';

export const TRICK_FORM_DATA_LOAD = 'TRICK_FORM_DATA_LOAD';
export const TRICK_FORM_DATA_SUCCESS = 'TRICK_FORM_DATA_SUCCESS';
export const TRICK_FORM_DATA_ERROR = 'TRICK_FORM_DATA_ERROR';

export const TRICK_EDIT_DATA_LOAD = 'TRICK_EDIT_DATA_LOAD';
export const TRICK_EDIT_DATA_SUCCESS = 'TRICK_EDIT_DATA_SUCCESS';
export const TRICK_EDIT_DATA_ERROR = 'TRICK_EDIT_DATA_ERROR';

export const TRICK_UPDATE_LOAD = 'TRICK_UPDATE_LOAD';
export const TRICK_UPDATE_SUCCESS = 'TRICK_UPDATE_SUCCESS';
export const TRICK_UPDATE_ERROR = 'TRICK_UPDATE_ERROR';

export const TRICK_NEW_LOAD = 'TRICK_NEW_LOAD';
export const TRICK_NEW_SUCCESS = 'TRICK_NEW_SUCCESS';
export const TRICK_NEW_ERROR = 'TRICK_NEW_ERROR';

interface TrickListLoadAction {
  type: typeof TRICK_LIST_LOAD;
}

interface TrickListSuccess {
  type: typeof TRICK_LIST_SUCCESS;
  payload: TricksListItem[];
}

interface TrickListError {
  type: typeof TRICK_LIST_ERROR;
}

interface TrickFormDataLoadAction {
  type: typeof TRICK_FORM_DATA_LOAD;
}

interface TrickFormDataSuccess {
  type: typeof TRICK_FORM_DATA_SUCCESS;
  payload: FormData;
}

interface TrickFormDataError {
  type: typeof TRICK_FORM_DATA_ERROR;
}

interface TrickEditDataLoadAction {
  type: typeof TRICK_EDIT_DATA_LOAD;
}

interface TrickEditDataSuccess {
  type: typeof TRICK_EDIT_DATA_SUCCESS;
  payload: TrickData;
}

interface TrickEditDataError {
  type: typeof TRICK_EDIT_DATA_ERROR;
}

interface TrickUpdateLoadAction {
  type: typeof TRICK_UPDATE_LOAD;
}

interface TrickUpdateSuccess {
  type: typeof TRICK_UPDATE_SUCCESS;
}

interface TrickUpdateError {
  type: typeof TRICK_UPDATE_ERROR;
  payload: Error;
}

interface TrickNewLoadAction {
  type: typeof TRICK_NEW_LOAD;
}

interface TrickNewSuccess {
  type: typeof TRICK_NEW_SUCCESS;
}

interface TrickNewError {
  type: typeof TRICK_NEW_ERROR;
  payload: Error;
}

export type TrickActionTypes =
  | TrickListLoadAction
  | TrickListSuccess
  | TrickListError
  | TrickFormDataLoadAction
  | TrickFormDataSuccess
  | TrickFormDataError
  | TrickEditDataLoadAction
  | TrickEditDataSuccess
  | TrickEditDataError
  | TrickUpdateLoadAction
  | TrickUpdateSuccess
  | TrickUpdateError
  | TrickNewLoadAction
  | TrickNewSuccess
  | TrickNewError;
