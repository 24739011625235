import { Subscription } from '../users/types';

export interface SubscriptionData {
  data: Subscription[];
  meta: {
    current_page: number;
    total: number;
    last_page: number;
  };
}

export type SubscriptionType = {
  isLoading: boolean;
  hasError: boolean;
  hasLoaded?: boolean;
  data?: SubscriptionData;
};

export type SubscriptionTerminatePayloadType = {
  reasons?: string[];
};

export type SubscriptionTerminateActionType = {
  isLoading: boolean;
  hasUpdated: boolean;
  hasError: boolean | null;
  error?: Error;
};

export interface SubscriptionState {
  isLoading: boolean;
  hasError: boolean;
  subscriptions: SubscriptionType;
  actionState: {
    subscriptionTerminateSave: SubscriptionTerminateActionType;
  };
}

// Describing the different ACTION NAMES available
// Subscription list load
export const SUBSCRIPTION_LIST_LOAD = 'SUBSCRIPTION_LIST_LOAD';
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
export const SUBSCRIPTION_LIST_ERROR = 'SUBSCRIPTION_LIST_ERROR';
// Subscription terminate action
export const SUBSCRIPTION_TERMINATE_ACTION_START =
  'SUBSCRIPTION_TERMINATE_ACTION_START';
export const SUBSCRIPTION_TERMINATE_ACTION_SUCCESS =
  'SUBSCRIPTION_TERMINATE_ACTION_SUCCESS';
export const SUBSCRIPTION_TERMINATE_ACTION_ERROR =
  'SUBSCRIPTION_TERMINATE_ACTION_ERROR';
export const SUBSCRIPTION_TERMINATE_ACTION_CLEAR =
  'SUBSCRIPTION_TERMINATE_ACTION_CLEAR';

interface SubscriptionListLoadAction {
  type: typeof SUBSCRIPTION_LIST_LOAD;
}

interface SubscriptionListSuccessAction {
  type: typeof SUBSCRIPTION_LIST_SUCCESS;
  payload: SubscriptionData;
}

interface SubscriptionListErrorAction {
  type: typeof SUBSCRIPTION_LIST_ERROR;
}

interface SubscriptionTerminateActionStart {
  type: typeof SUBSCRIPTION_TERMINATE_ACTION_START;
}

interface SubscriptionTerminateActionSuccess {
  type: typeof SUBSCRIPTION_TERMINATE_ACTION_SUCCESS;
}

interface SubscriptionTerminateActionError {
  type: typeof SUBSCRIPTION_TERMINATE_ACTION_ERROR;
  payload: Error;
}

interface SubscriptionTerminateActionClear {
  type: typeof SUBSCRIPTION_TERMINATE_ACTION_CLEAR;
}

export type SubscriptionActionTypes =
  | SubscriptionListLoadAction
  | SubscriptionListSuccessAction
  | SubscriptionListErrorAction
  | SubscriptionTerminateActionStart
  | SubscriptionTerminateActionSuccess
  | SubscriptionTerminateActionError
  | SubscriptionTerminateActionClear;
