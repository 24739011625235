import * as t from './types';

const initialState: t.UserLifetimeValueState = {
  hasError: false,
  isLoading: false,
  filters: {
    locales: [],
    from: '',
    to: '',
    scope: t.OriginScope.user,
    userScope: t.UserScope.userOriginDate,
    platforms: [],
    offers: [],
    pipeline: '',
    subOrigin: t.SubOrigin.pipe,
    timeTo: t.TimeTo.today,
    period: '0',
  },
  formData: {
    offers: [],
  },
};

const userLifetimeValueReducer = (
  state = initialState,
  action: t.UserLifetimeValueActionTypes
): t.UserLifetimeValueState => {
  switch (action.type) {
    case t.DATA_LOAD:
    case t.FORM_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case t.FORM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formData: action.payload,
      };

    case t.DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
        filters: action.filters,
      };

    case t.DATA_ERROR:
    case t.FORM_DATA_ERROR:
      return { ...state, hasError: true, isLoading: false };

    case t.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };

    default:
      return state;
  }
};

export default userLifetimeValueReducer;
