import * as t from './types';

const initialState: t.TricksState = {
  isLoading: false,
  hasError: false,
  listItems: [],
  edit: {
    isLoading: false,
    hasError: false,
    trick: {
      code: '',
      state: '',
      titles: {},
      recipes: {},
      goals: [],
      images: {},
      benefits: [],
      updated: '',
    },
  },
  formData: {
    benefits: [],
  },
  submit: {
    isLoading: false,
    hasError: false,
  },
};

const tricksReducer = (
  state = initialState,
  action: t.TrickActionTypes
): t.TricksState => {
  switch (action.type) {
    case t.TRICK_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.TRICK_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listItems: action.payload,
      };
    case t.TRICK_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case t.TRICK_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.payload,
      };
    case t.TRICK_EDIT_DATA_LOAD:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true,
        },
      };
    case t.TRICK_EDIT_DATA_SUCCESS:
      return {
        ...state,
        edit: {
          isLoading: false,
          hasError: false,
          trick: action.payload,
        },
      };
    case t.TRICK_FORM_DATA_ERROR:
    case t.TRICK_EDIT_DATA_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          hasError: true,
        },
      };
    case t.TRICK_NEW_LOAD:
    case t.TRICK_UPDATE_LOAD:
      return {
        ...state,
        submit: {
          isLoading: true,
          hasError: false,
        },
      };
    case t.TRICK_NEW_SUCCESS:
    case t.TRICK_UPDATE_SUCCESS:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: false,
        },
      };
    case t.TRICK_NEW_ERROR:
    case t.TRICK_UPDATE_ERROR:
      return {
        ...state,
        submit: {
          isLoading: false,
          hasError: true,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default tricksReducer;
