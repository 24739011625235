import {
  EARLY_WARNING_CURRENT_ERROR,
  EARLY_WARNING_CURRENT_LOAD,
  EARLY_WARNING_CURRENT_SAVE,
  EARLY_WARNING_CURRENT_SAVE_ERROR,
  EARLY_WARNING_CURRENT_SAVE_SUCCESS,
  EARLY_WARNING_CURRENT_SUCCESS,
  EARLY_WARNING_LIST_ERROR,
  EARLY_WARNING_LIST_LOAD,
  EARLY_WARNING_LIST_SUCCESS,
  EARLY_WARNING_REFUND_SAVE,
  EARLY_WARNING_REFUND_SAVE_ERROR,
  EARLY_WARNING_REFUND_SAVE_SUCCESS,
  EarlyWarningActionTypes,
  EarlyWarningState,
} from './types';

const initialState: EarlyWarningState = {
  isLoading: false,
  hasError: false,
  actionState: {
    paymentRefundSave: {
      isLoading: false,
      hasUpdated: false,
    },
    earlyWarningSave: {
      isLoading: false,
      hasUpdated: false,
    },
  },
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  current: {
    data: {
      id: '',
      service_name: '',
      status: '',
      external_id: '',
      external_sync_at: '',
      payment_amount: '',
      payment_amount_with_cents: '',
      payment_currency: '',
      payment_descriptor: '',
      payment_created_at: '',
      created_at: '',
      card_last_digits: '',
      card_number: '',
      card_issuer: '',
      payments_count: '',
      payments: [],
      payment: null,
      completed_by: null,
      received_at: '',
    },
  },
  mapping: {
    status: {
      new: 'grey',
      completed: 'green',
      error: 'red',
      refunded: 'yellow',
      already_refunded: 'yellow',
      auto_completed: 'green',
    },
  },
};

const earlyWarningReducer = (
  state = initialState,
  action: EarlyWarningActionTypes
): EarlyWarningState => {
  switch (action.type) {
    case EARLY_WARNING_LIST_LOAD || EARLY_WARNING_CURRENT_LOAD:
      return {
        ...state,
        isLoading: true,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case EARLY_WARNING_CURRENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        current: action.payload,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_LIST_ERROR:
    case EARLY_WARNING_CURRENT_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EARLY_WARNING_CURRENT_SAVE:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: true,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_CURRENT_SAVE_SUCCESS:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: true,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_CURRENT_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
            error: action.payload,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_REFUND_SAVE:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: true,
            hasUpdated: false,
          },
        },
      };

    case EARLY_WARNING_REFUND_SAVE_SUCCESS:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: true,
          },
        },
      };

    case EARLY_WARNING_REFUND_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          earlyWarningSave: {
            isLoading: false,
            hasUpdated: false,
          },
          paymentRefundSave: {
            isLoading: false,
            hasUpdated: false,
            error: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default earlyWarningReducer;
