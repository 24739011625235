/* eslint-disable @typescript-eslint/camelcase */
import * as t from './types';

const initialState: t.SubscriptionState = {
  isLoading: false,
  hasError: false,
  subscriptions: {
    isLoading: false,
    hasError: false,
    hasLoaded: false,
    data: {
      data: [],
      meta: {
        current_page: 1,
        total: 1,
        last_page: 1,
      },
    },
  },
  actionState: {
    subscriptionTerminateSave: {
      isLoading: false,
      hasUpdated: false,
      hasError: false,
    },
  },
};

const subscriptionReducer = (
  state = initialState,
  action: t.SubscriptionActionTypes
): t.SubscriptionState => {
  switch (action.type) {
    case t.SUBSCRIPTION_LIST_LOAD:
      return {
        ...state,
        subscriptions: {
          isLoading: true,
          hasError: false,
          hasLoaded: false,
          data: state.subscriptions.data,
        },
      };
    case t.SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptions: {
          isLoading: false,
          hasError: false,
          hasLoaded: true,
          data: action.payload,
        },
      };
    case t.SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        subscriptions: {
          isLoading: false,
          hasError: true,
          hasLoaded: false,
          data: state.subscriptions.data,
        },
      };

    case t.SUBSCRIPTION_TERMINATE_ACTION_START:
      return {
        ...state,
        actionState: {
          subscriptionTerminateSave: {
            isLoading: true,
            hasError: false,
            hasUpdated: false,
          },
        },
      };
    case t.SUBSCRIPTION_TERMINATE_ACTION_SUCCESS:
      return {
        ...state,
        actionState: {
          subscriptionTerminateSave: {
            isLoading: false,
            hasError: false,
            hasUpdated: true,
          },
        },
      };
    case t.SUBSCRIPTION_TERMINATE_ACTION_ERROR:
      return {
        ...state,
        actionState: {
          subscriptionTerminateSave: {
            isLoading: false,
            hasUpdated: false,
            hasError: true,
            error: action.payload,
          },
        },
      };
    case t.SUBSCRIPTION_TERMINATE_ACTION_CLEAR:
      return {
        ...state,
        actionState: {
          subscriptionTerminateSave: {
            isLoading: false,
            hasUpdated: false,
            hasError: null,
          },
        },
      };
    default: {
      return state;
    }
  }
};

export default subscriptionReducer;
