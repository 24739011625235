import { PurchaseItem } from 'store/purchaseItems/types';

/* eslint-disable @typescript-eslint/camelcase */
export enum SearchType {
  email = 'email',
  emailStrict = 'emailStrict',
  phone = 'phone',
  name = 'name',
  transactionId = 'transactionId',
  invoiceId = 'invoiceId',
  newMessages = 'newMessages',
  userId = 'userId',
}

export enum SearchValue {
  email = 'searchEmail',
  emailStrict = 'searchEmailStrict',
  phone = 'searchPhone',
  name = 'searchName',
  transactionId = 'searchTransactionId',
  invoiceId = 'searchInvoiceId',
  userId = 'searchUserId',
}

export interface ConversationListItem {
  id: number;
  name: string;
  gender: string;
  country: string;
  age: number;
  goal: string;
  currentWeight: number;
  goalWeight: number;
  state: string;
  subscription: {
    label: string;
    status: string;
  };
  lastMessage?: {
    text: string;
    timestamp: string;
  };
}

export interface Conversations {
  isLoading: boolean;
  currentPage: number;
  totalCount: number;
  items: Array<ConversationListItem>;
}

export interface InitialChatData {
  panelInfo: ChatPanelInfo;
  conversations: Conversations;
  cableEndpoint: CableEndpoint;
}

export interface ChatPanelInfo {
  unread: number | null;
  translationQueue: number | null;
}

export interface CableEndpoint {
  loaded: boolean;
  action: string;
  auth_endpoint: string;
  app_key: string;
  cluster: string;
}

export type CurrencyType = {
  id: number;
  symbol: string;
  name: string;
};

export type PremiumAddType = {
  isLoading: boolean;
  hasError: boolean | null;
};

export enum AccessTypes {
  proSubscription = 'pro_subscription',
  premiumUpsell = 'premium_upsell',
}

export type Ticket = {
  id: number;
  paid_from: string;
  paid_till: string;
  payment_id: number;
  purchase_item: PurchaseItem;
  subscription_id: number;
};

export type Tickets = {
  isLoading: boolean;
  hasError: boolean;
  tickets?: Ticket[];
};

export type TerminateTicketType = {
  isLoading: boolean;
  hasError: boolean;
};

export type StoreTicketType = {
  isLoading: boolean;
  hasError: boolean;
};

export enum SubscriptionStatusType {
  active = 1,
  unsubscribed = 2,
  failed = 3,
  temporal = 4,
  suspended = 5,
  cardExpired = 6,
}

export type Subscription = {
  id: number;
  currency: string;
  next_charge_time: string;
  trial_ends_at: string;
  payment_method: string;
  price: string;
  started_at: string;
  status: SubscriptionStatusType;
  activities?: SubscriptionActivity[];
  ticket_id: number;
  type: string;
  purchase_item: PurchaseItem;
  brand?: string;
};

export type SubscriptionActivity = {
  id: number;
  type: SubscriptionActivityType;
  sub_type: SubscriptionActivitySubType;
  created_at: string;
  updated_at: string;
  properties?: SubscriptionActivityProperties;
  admin_user?: {
    id: number;
    first_name: string;
    last_name: string;
  };
};

export type SubscriptionActivityProperties = {
  reasons?: string[];
};

export enum SubscriptionActivityType {
  Unsubscribe = 'unsubscribe',
  Pause = 'pause',
  Unpause = 'unpause',
}

export enum SubscriptionActivitySubType {
  Support = 'support',
  User = 'user',
  TosDeclined = 'tos_declined',
  Suspended = 'suspended',
  Expired = 'expired',
  RemoteTerminated = 'remote_terminated',
  EarlyWarning = 'early_warning',
  ChargeFailed = 'charge_failed',
  Dispute = 'dispute',
}

export type Subscriptions = {
  isLoading: boolean;
  hasError: boolean;
  subscriptions?: Subscription[];
};

export type TerminateSubscriptionType = {
  isLoading: boolean;
  hasError: boolean;
};

export interface UserInfo {
  id: number;
  access: Array<AccessTypes>;
  accessToken: string;
  name: string;
  gender: string;
  country: string;
  locale: string;
  age: number;
  goal: string;
  currentWeight: number;
  goalWeight: number;
  height: number;
  phone: string;
  email: string;
  programInfo: {
    registered: string;
    goalFatPercentage: number;
    fatPercentage: number;
    problemZones: Array<string>;
  };
  subscriptionInfo: {
    startedAt: string;
    nextCharge: string;
    price: number;
    currency: string;
    humanReadableProvider: string;
    provider: string;
    status: string;
    graceTill: string;
  };
  profileLink: string;
  socketChannel: string;
  premiumAdd?: PremiumAddType;
  tickets?: Tickets;
  terminateTicket?: TerminateTicketType;
  storeTicket?: StoreTicketType;
  subscriptions?: Subscriptions;
  terminateSubscription?: TerminateSubscriptionType;
  active_brand?: string;
  origin_brand?: string;
  finished_registration?: boolean;
  custom_fields: UserCustomField[];
  quiz_data: UserQuizData;
}

export interface UserCustomField {
  field: string;
  value: string;
  created_at: string;
}

export interface UserQuizData {
  created_at: string;
  questions: {
    [key: string]: string[];
  };
}

export enum MessageType {
  message = 0,
  callLog = 1,
  gdprDisclaimer = 2,
}

export interface ChatMessage {
  message: string;
  timestamp: string;
  messageType: MessageType;
  user: {
    type: string;
    name: string;
  };
  id: number;
}

export interface Messages {
  isLoading: boolean;
  items: Array<ChatMessage>;
  loadMore: boolean;
  userId: number | null;
}

export interface ActiveChatType {
  userInfo?: UserInfo;
  fieldUpdateStatus: Partial<Record<keyof UserInfo, boolean>>;
  messages: Messages;
  isLoading: boolean;
  isDeleteLoading: boolean;
}

// Users part of global store
export interface UsersState {
  isLoading: boolean;
  hasError: boolean;
  panelInfo: ChatPanelInfo;
  conversations: Conversations;
  activeChat: ActiveChatType;
  cableEndpoint: CableEndpoint;
}

// Describing the different ACTION NAMES available
export const INITIAL_DATA_LOAD = 'INITIAL_DATA_LOAD';
export const INITIAL_DATA_SUCCESS = 'INITIAL_DATA_SUCCESS';
export const INITIAL_DATA_ERROR = 'INITIAL_DATA_ERROR';

export const CONVERSATION_LIST_LOAD = 'CONVERSATION_LIST_LOAD';
export const CONVERSATION_LIST_SUCCESS = 'CONVERSATION_LIST_SUCCESS';
export const CONVERSATION_LIST_ERROR = 'CONVERSATION_LIST_ERROR';

export const CHAT_DATA_LOAD = 'CHAT_DATA_LOAD';
export const CHAT_DATA_SUCCESS = 'CHAT_DATA_SUCCESS';
export const CHAT_DATA_ERROR = 'CHAT_DATA_ERROR';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECT_SUCCESS = 'SOCKET_CONNECT_SUCCESS';
export const SOCKET_CONNECT_ERROR = 'SOCKET_CONNECT_ERROR';

export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_DISCONNECT_SUCCESS = 'SOCKET_DISCONNECT_SUCCESS';
export const SOCKET_DISCONNECT_ERROR = 'SOCKET_DISCONNECT_ERROR';

export const CHANNEL_UNSUBSCRIBE = 'CHANNEL_UNSUBSCRIBE';
export const CHANNEL_UNSUBSCRIBE_SUCCESS = 'CHANNEL_UNSUBSCRIBE_SUCCESS';
export const CHANNEL_UNSUBSCRIBE_ERROR = 'CHANNEL_UNSUBSCRIBE_ERROR';

export const CHAT_MESSAGES_LOAD = 'CHAT_MESSAGES_LOAD';
export const CHAT_MESSAGES_CLEAR = 'CHAT_MESSAGES_CLEAR';
export const CHAT_MESSAGES_SUCCESS = 'CHAT_MESSAGES_SUCCESS';
export const CHAT_MESSAGES_ERROR = 'CHAT_MESSAGES_ERROR';

export const MARK_USER_AS_DONE = 'MARK_USER_AS_DONE';
export const MARK_USER_AS_DONE_ERROR = 'MARK_USER_AS_DONE_ERROR';

export const USER_FIELD_UPDATE_LOAD = 'USER_FIELD_UPDATE_LOAD';
export const USER_FIELD_UPDATE_SUCCESS = 'USER_FIELD_UPDATE_SUCCESS';
export const USER_FIELD_UPDATE_ERROR = 'USER_FIELD_UPDATE_ERROR';

export const CHAT_MESSAGE_SEND = 'CHAT_MESSAGE_SEND';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';
export const MESSAGE_SEND_ERROR = 'MESSAGE_SEND_ERROR';

export const DELETE_USER_LOAD = 'DELETE_USER_LOAD';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const PREMIUM_ADD_LOAD = 'PREMIUM_ADD_LOAD';
export const PREMIUM_ADD_SUCCESS = 'PREMIUM_ADD_SUCCESS';
export const PREMIUM_ADD_ERROR = 'PREMIUM_ADD_ERROR';
export const PREMIUM_ADD_CLEAR = 'PREMIUM_ADD_CLEAR';

export const TICKETS_LOAD = 'TICKETS_LOAD';
export const TICKETS_SUCCESS = 'TICKETS_SUCCESS';
export const TICKETS_ERROR = 'TICKETS_ERROR';

export const TERMINATE_TICKET_LOAD = 'TERMINATE_TICKET_LOAD';
export const TERMINATE_TICKET_SUCCESS = 'TERMINATE_TICKET_SUCCESS';
export const TERMINATE_TICKET_ERROR = 'TERMINATE_TICKET_ERROR';
export const TERMINATE_TICKET_RESET = 'TERMINATE_TICKET_RESET';

export const STORE_TICKET_LOAD = 'STORE_TICKET_LOAD';
export const STORE_TICKET_SUCCESS = 'STORE_TICKET_SUCCESS';
export const STORE_TICKET_ERROR = 'STORE_TICKET_ERROR';
export const STORE_TICKET_RESET = 'STORE_TICKET_RESET';

export const SUBSCRIPTIONS_LOAD = 'SUBSCRIPTIONS_LOAD';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_ERROR';

export const TERMINATE_SUBSCRIPTION_LOAD = 'TERMINATE_SUBSCRIPTION_LOAD';
export const TERMINATE_SUBSCRIPTION_SUCCESS = 'TERMINATE_SUBSCRIPTION_SUCCESS';
export const TERMINATE_SUBSCRIPTION_ERROR = 'TERMINATE_SUBSCRIPTION_ERROR';
export const TERMINATE_SUBSCRIPTION_RESET = 'TERMINATE_SUBSCRIPTION_RESET';

interface InitialDataLoad {
  type: typeof INITIAL_DATA_LOAD;
}

interface InitialDataSuccess {
  type: typeof INITIAL_DATA_SUCCESS;
  payload: InitialChatData;
}

interface InitialDataError {
  type: typeof INITIAL_DATA_ERROR;
}

interface ConversationListLoadAction {
  type: typeof CONVERSATION_LIST_LOAD;
}

interface ConversationListSuccess {
  type: typeof CONVERSATION_LIST_SUCCESS;
  payload: Conversations;
}

interface ConversationListError {
  type: typeof CONVERSATION_LIST_ERROR;
}

interface ChatDataLoadAction {
  type: typeof CHAT_DATA_LOAD;
}

interface ChatDataSuccess {
  type: typeof CHAT_DATA_SUCCESS;
  payload: ActiveChatType;
}

interface ChatDataError {
  type: typeof CHAT_DATA_ERROR;
}

interface SocketConnect {
  type: typeof SOCKET_CONNECT;
}

interface SocketConnectSuccess {
  type: typeof SOCKET_CONNECT_SUCCESS;
}

interface SocketConnectError {
  type: typeof SOCKET_CONNECT_ERROR;
}

interface SocketDisconnect {
  type: typeof SOCKET_DISCONNECT;
}

interface SocketDisconnectSuccess {
  type: typeof SOCKET_DISCONNECT_SUCCESS;
}

interface SocketDisconnectError {
  type: typeof SOCKET_DISCONNECT_ERROR;
}

interface ChannelUnsubscribe {
  type: typeof CHANNEL_UNSUBSCRIBE;
}

interface ChannelUnsubscribeSuccess {
  type: typeof CHANNEL_UNSUBSCRIBE_SUCCESS;
}

interface ChannelUnsubscribeError {
  type: typeof CHANNEL_UNSUBSCRIBE_ERROR;
}

interface MarkUserAsDoneAction {
  type: typeof MARK_USER_AS_DONE;
  payload: number;
}

interface MarkUserAsDoneError {
  type: typeof MARK_USER_AS_DONE_ERROR;
}

interface UpdateUserFieldAction {
  type: typeof USER_FIELD_UPDATE_LOAD;
  payload: keyof UserInfo;
}

interface UpdateUserFieldSuccess {
  type: typeof USER_FIELD_UPDATE_SUCCESS;
  payload: keyof UserInfo;
}

interface UpdateUserFieldError {
  type: typeof USER_FIELD_UPDATE_ERROR;
  payload: keyof UserInfo;
}

interface ChatMessageLoadAction {
  type: typeof CHAT_MESSAGES_LOAD;
}

interface ChatMessageClearAction {
  type: typeof CHAT_MESSAGES_CLEAR;
}

interface ChatMessageSuccess {
  type: typeof CHAT_MESSAGES_SUCCESS;
  payload: Messages;
}

interface ChatMessageError {
  type: typeof CHAT_MESSAGES_ERROR;
}

interface ChatMessageSendAction {
  type: typeof CHAT_MESSAGE_SEND;
}

interface MessageSendSuccess {
  type: typeof MESSAGE_SEND_SUCCESS;
  payload: ChatMessage;
}

interface MessageSendError {
  type: typeof MESSAGE_SEND_ERROR;
}

interface DeleteUserLoadAction {
  type: typeof DELETE_USER_LOAD;
}

interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
  payload: number;
}

interface DeleteUserError {
  type: typeof DELETE_USER_ERROR;
}

interface PremiumAddLoadAction {
  type: typeof PREMIUM_ADD_LOAD;
}

interface PremiumAddSuccessAction {
  type: typeof PREMIUM_ADD_SUCCESS;
}

interface PremiumAddErrorAction {
  type: typeof PREMIUM_ADD_ERROR;
}

interface PremiumAddClearAction {
  type: typeof PREMIUM_ADD_CLEAR;
}

interface TicketsLoadAction {
  type: typeof TICKETS_LOAD;
}
interface TicketsSuccessAction {
  type: typeof TICKETS_SUCCESS;
  payload: Ticket[];
}
interface TicketsErrorAction {
  type: typeof TICKETS_ERROR;
}

interface TerminateTicketLoadAction {
  type: typeof TERMINATE_TICKET_LOAD;
}
interface TerminateTicketSuccessAction {
  type: typeof TERMINATE_TICKET_SUCCESS;
}
interface TerminateTicketErrorAction {
  type: typeof TERMINATE_TICKET_ERROR;
}
interface TerminateTicketResetAction {
  type: typeof TERMINATE_TICKET_RESET;
}
interface StoreTicketLoadAction {
  type: typeof STORE_TICKET_LOAD;
}
interface StoreTicketSuccessAction {
  type: typeof STORE_TICKET_SUCCESS;
}
interface StoreTicketErrorAction {
  type: typeof STORE_TICKET_ERROR;
}
interface StoreTicketResetAction {
  type: typeof STORE_TICKET_RESET;
}
interface SubscriptionsLoadAction {
  type: typeof SUBSCRIPTIONS_LOAD;
}
interface SubscriptionsSuccessAction {
  type: typeof SUBSCRIPTIONS_SUCCESS;
  payload: Subscription[];
}
interface SubscriptionsErrorAction {
  type: typeof SUBSCRIPTIONS_ERROR;
}

interface TerminateSubscriptionLoadAction {
  type: typeof TERMINATE_SUBSCRIPTION_LOAD;
}
interface TerminateSubscriptionSuccessAction {
  type: typeof TERMINATE_SUBSCRIPTION_SUCCESS;
}
interface TerminateSubscriptionErrorAction {
  type: typeof TERMINATE_SUBSCRIPTION_ERROR;
}
interface TerminateSubscriptionResetAction {
  type: typeof TERMINATE_SUBSCRIPTION_RESET;
}

export type UserActionTypes =
  | InitialDataLoad
  | InitialDataSuccess
  | InitialDataError
  | ConversationListLoadAction
  | ConversationListSuccess
  | ConversationListError
  | ChatDataLoadAction
  | ChatDataSuccess
  | ChatDataError
  | SocketConnect
  | SocketConnectSuccess
  | SocketConnectError
  | SocketDisconnect
  | SocketDisconnectSuccess
  | SocketDisconnectError
  | ChannelUnsubscribe
  | ChannelUnsubscribeSuccess
  | ChannelUnsubscribeError
  | MarkUserAsDoneAction
  | MarkUserAsDoneError
  | UpdateUserFieldAction
  | UpdateUserFieldSuccess
  | UpdateUserFieldError
  | ChatMessageLoadAction
  | ChatMessageSuccess
  | ChatMessageClearAction
  | ChatMessageError
  | ChatMessageSendAction
  | MessageSendSuccess
  | MessageSendError
  | DeleteUserLoadAction
  | DeleteUserSuccess
  | DeleteUserError
  | PremiumAddLoadAction
  | PremiumAddSuccessAction
  | PremiumAddErrorAction
  | PremiumAddClearAction
  | TicketsLoadAction
  | TicketsSuccessAction
  | TicketsErrorAction
  | TerminateTicketLoadAction
  | TerminateTicketSuccessAction
  | TerminateTicketErrorAction
  | TerminateTicketResetAction
  | StoreTicketLoadAction
  | StoreTicketSuccessAction
  | StoreTicketErrorAction
  | StoreTicketResetAction
  | SubscriptionsLoadAction
  | SubscriptionsSuccessAction
  | SubscriptionsErrorAction
  | TerminateSubscriptionLoadAction
  | TerminateSubscriptionSuccessAction
  | TerminateSubscriptionErrorAction
  | TerminateSubscriptionResetAction;
