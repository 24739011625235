/* eslint-disable no-shadow */
/**
 * Format camelCase string to under_score
 * @param string {String}
 * @return {String}
 */
export const camelCaseToUnderscore = (string: string) => {
  return string
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .join('_')
    .toLowerCase();
};

/**
 * Desnakes a string with/without a custom separator (by default space)
 * (snake_case => snake case)
 * @param str {String}
 * @param separator {String}
 * @return {string }
 */
export const desnake = (str: string, separator?: string | undefined) => {
  if (!str) {
    return '';
  }
  return str.replace(/_/g, typeof separator === 'undefined' ? ' ' : separator);
};

/**
 * Format string to capitalized string
 * (capitalize => Capitalize)
 * @param str {string}
 */
export const capitalize = (str: string | undefined) => {
  if (!str) {
    return null;
  }
  return str.replace(/^./, (str) => str.toUpperCase());
};

/**
 * Replaces string value with symbol (Best used in table rows)
 * Ex: value = "" or null -> will return "-"
 * @param string {String}
 * @param symbol {String}
 * @return {String}
 */
export const valueCheckReplacer = (
  string?: string | number | null,
  symbol = '-'
) => {
  if (!string || (typeof string === 'string' && string.length === 0)) {
    return symbol;
  }
  return string;
};

/**
 * Converts full amount to cents
 * Ex: Dollars/Euro to cents (5.50 -> 550)
 * @param value {Number}
 * @return {Number}
 */
export const fullAmountToCents = (value: number) => {
  return value ? Math.round(value * 100) : 0.0;
};

/**
 * Convert cents to full amount
 * Ex: Dollars/Euro to cents (550 -> 5.50)
 * @param value {Number}
 * @return {Number}
 */
export const centsToFullAmount = (value: number) => {
  return value ? (value / 100).toFixed(2) : 0.0;
};
