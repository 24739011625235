import { LocalizedValues, MediaType } from 'types';

export interface WorkoutListItem {
  id: number;
  title: string;
  code: string;
  updated: string;
}

export enum Visibility {
  normal = 'normal',
  ghost = 'ghost',
}

export interface Exercise {
  code: string;
  id: number;
}

export interface ExerciseItemType {
  code: string;
  id: number;
  repetitions: number;
  duration?: number;
  visibility: Visibility;
  editMode?: boolean;
}

interface WorkoutCommon {
  updated: string;
  code: string;
  titles: LocalizedValues<string>;
  descriptions: LocalizedValues<string>;
  images: LocalizedValues<string> | LocalizedValues<MediaType>;
  images_male: LocalizedValues<string> | LocalizedValues<MediaType>;
  images_female: LocalizedValues<string> | LocalizedValues<MediaType>;
  workoutTime: number;
  defaultImage?: MediaType;
  exercises: ExerciseItemType[];
  showcaseExerciseId: number | null;
  benefits: string[];
}

export interface WorkoutData extends WorkoutCommon {
  images: LocalizedValues<MediaType>;
  images_male: LocalizedValues<MediaType>;
  images_female: LocalizedValues<MediaType>;
}

export interface WorkoutDataSubmit extends WorkoutCommon {
  images: LocalizedValues<string>;
  images_male: LocalizedValues<string>;
  images_female: LocalizedValues<string>;
}

export interface WorkoutEditState {
  isLoading: boolean;
  hasError: boolean;
  workout: WorkoutData;
  exerciseTypes: Exercise[];
}

export interface WorkoutSubmitState {
  isLoading: boolean;
  hasError: boolean;
  error?: Error;
}

// Workouts part of global store
export interface WorkoutsState {
  isLoading: boolean;
  hasError: boolean;
  listItems: Array<WorkoutListItem>;
  edit: WorkoutEditState;
  submit: WorkoutSubmitState;
}

export const WORKOUTS_LIST_LOAD = 'WORKOUTS_LIST_LOAD';
export const WORKOUTS_LIST_SUCCESS = 'WORKOUTS_LIST_SUCCESS';
export const WORKOUTS_LIST_ERROR = 'WORKOUTS_LIST_ERROR';

export const EXERCISE_LOAD = 'WORKOUTS_EXERCISE_LOAD';
export const EXERCISE_SUCCESS = 'WORKOUTS_EXERCISE_SUCCESS';
export const EXERCISE_ERROR = 'WORKOUTS_EXERCISE_ERROR';

export const WORKOUTS_EDIT_LOAD = 'WORKOUTS_EDIT_LOAD';
export const WORKOUTS_EDIT_SUCCESS = 'WORKOUTS_EDIT_SUCCESS';
export const WORKOUTS_EDIT_ERROR = 'WORKOUTS_EDIT_ERROR';

export const WORKOUTS_UPDATE_LOAD = 'WORKOUTS_UPDATE_LOAD';
export const WORKOUTS_UPDATE_SUCCESS = 'WORKOUTS_UPDATE_SUCCESS';
export const WORKOUTS_UPDATE_ERROR = 'WORKOUTS_UPDATE_ERROR';

export const WORKOUTS_NEW_LOAD = 'WORKOUTS_NEW_LOAD';
export const WORKOUTS_NEW_SUCCESS = 'WORKOUTS_NEW_SUCCESS';
export const WORKOUTS_NEW_ERROR = 'WORKOUTS_NEW_ERROR';

interface WorkoutListLoadAction {
  type: typeof WORKOUTS_LIST_LOAD;
}

interface WorkoutListSuccessAction {
  type: typeof WORKOUTS_LIST_SUCCESS;
  payload: Array<WorkoutListItem>;
}

interface WorkoutListErrorAction {
  type: typeof WORKOUTS_LIST_ERROR;
}

interface ExerciseLoadAction {
  type: typeof EXERCISE_LOAD;
}

interface ExerciseSuccessAction {
  type: typeof EXERCISE_SUCCESS;
  payload: Exercise[];
}

interface ExerciseErrorAction {
  type: typeof EXERCISE_ERROR;
}

interface WorkoutEditLoadAction {
  type: typeof WORKOUTS_EDIT_LOAD;
}

interface WorkoutEditSuccessAction {
  type: typeof WORKOUTS_EDIT_SUCCESS;
  payload: WorkoutData;
}

interface WorkoutEditErrorAction {
  type: typeof WORKOUTS_EDIT_ERROR;
}

interface WorkoutUpdateLoadAction {
  type: typeof WORKOUTS_UPDATE_LOAD;
}

interface WorkoutUpdateSuccessAction {
  type: typeof WORKOUTS_UPDATE_SUCCESS;
}

interface WorkoutUpdateErrorAction {
  type: typeof WORKOUTS_UPDATE_ERROR;
  payload: Error;
}

interface WorkoutNewLoadAction {
  type: typeof WORKOUTS_NEW_LOAD;
}

interface WorkoutNewSuccessAction {
  type: typeof WORKOUTS_NEW_SUCCESS;
}

interface WorkoutNewErrorAction {
  type: typeof WORKOUTS_NEW_ERROR;
  payload: Error;
}

export type WorkoutActionTypes =
  | WorkoutListLoadAction
  | WorkoutListSuccessAction
  | WorkoutListErrorAction
  | ExerciseLoadAction
  | ExerciseSuccessAction
  | ExerciseErrorAction
  | WorkoutEditLoadAction
  | WorkoutEditSuccessAction
  | WorkoutEditErrorAction
  | WorkoutUpdateLoadAction
  | WorkoutUpdateSuccessAction
  | WorkoutUpdateErrorAction
  | WorkoutNewLoadAction
  | WorkoutNewSuccessAction
  | WorkoutNewErrorAction;
