import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// eslint-disable-next-line import/prefer-default-export
export const bugsnagClient = Bugsnag.start({
  apiKey: 'e25bc60099cae76eab7b9fccfbac6767',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
  appVersion: process.env.REACT_APP_VERSION,
});
