export type CurrencyType = {
  id: number;
  symbol: string;
  name: string;
};

export type PaymentRefundListItem = {
  id: number;
  amount: number;
  created_at: string;
  updated_at: string;
  reasons: string[];
  admin_user?: {
    first_name: string;
    last_name: string;
  };
};

export interface PaymentItem {
  id: number;
  created_at: string;
  provider: string;
  amount: string;
  refund_remainder: number;
  status_code: string;
  amount_in_euro: string;
  currency: CurrencyType;
  transaction_id: number;
  status: string;
  refundable: boolean;
  partially_refundable: boolean;
  refunded_amount?: null | string;
  refunded_percentage?: null | string;
  invoice_id?: number;
  purchaseItem?: {
    title: string;
  };
  processor?: null | string;
  refunds: PaymentRefundListItem[];
  brand?: string;
  is_trial?: string;
  disputed_at: null | string;
  invoice_url: string;
}

export interface PaymentData {
  data: PaymentItem[];
  meta: {
    current_page: number;
    total: number;
    last_page: number;
  };
}

export type PaymentsType = {
  isLoading: boolean;
  hasError: boolean;
  hasLoaded?: boolean;
  data?: PaymentData;
};

export type PaymentRefundPayloadType = {
  amount: number;
  reasons?: string[];
};

export type MarkPaymentAsRefundedPayloadType = {
  reasons: string[];
};

export type PaymentRefundActionType = {
  isLoading: boolean;
  hasUpdated: boolean;
  hasError: boolean | null;
  error?: Error;
};

// Users part of global store
export interface PaymentState {
  isLoading: boolean;
  hasError: boolean;
  payments: PaymentsType;
  actionState: {
    paymentRefundSave: PaymentRefundActionType;
  };
  mapping: {
    paymentStatus: object;
  };
}

// Describing the different ACTION NAMES available
// Payment list load
export const PAYMENT_LIST_LOAD = 'PAYMENT_LIST_LOAD';
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const PAYMENT_LIST_ERROR = 'PAYMENT_LIST_ERROR';
// Payment refund action
export const PAYMENT_REFUND_ACTION_START = 'PAYMENT_REFUND_ACTION_START';
export const PAYMENT_REFUND_ACTION_SUCCESS = 'PAYMENT_REFUND_ACTION_SUCCESS';
export const PAYMENT_REFUND_ACTION_ERROR = 'PAYMENT_REFUND_ACTION_ERROR';
export const PAYMENT_REFUND_ACTION_CLEAR = 'PAYMENT_REFUND_ACTION_CLEAR';

interface PaymentListLoadAction {
  type: typeof PAYMENT_LIST_LOAD;
}

interface PaymentListSuccessAction {
  type: typeof PAYMENT_LIST_SUCCESS;
  payload: PaymentData;
}

interface PaymentListErrorAction {
  type: typeof PAYMENT_LIST_ERROR;
}

interface PaymentRefundActionStart {
  type: typeof PAYMENT_REFUND_ACTION_START;
}

interface PaymentRefundActionSuccess {
  type: typeof PAYMENT_REFUND_ACTION_SUCCESS;
}

interface PaymentRefundActionError {
  type: typeof PAYMENT_REFUND_ACTION_ERROR;
  payload: Error;
}

interface PaymentRefundActionClear {
  type: typeof PAYMENT_REFUND_ACTION_CLEAR;
}

export type PaymentActionTypes =
  | PaymentListLoadAction
  | PaymentListSuccessAction
  | PaymentListErrorAction
  | PaymentRefundActionStart
  | PaymentRefundActionSuccess
  | PaymentRefundActionError
  | PaymentRefundActionClear;
