import { MonacofitDietType } from 'assets/constants';
import { LocalizedValues } from 'types';

export interface ProductListItem {
  id: number;
  title: string;
  code: string;
  updated: string;
}

export enum Unit {
  g = 'g',
  ml = 'ml',
}
export enum Visibility {
  visible = 'visible',
  invisible = 'invisible',
}

export interface ProductData {
  updated: string;
  code: string;
  titles: LocalizedValues<string>;
  emoji: string;
  unit: Unit;
  unitPerPiece?: number;
  diets: MonacofitDietType[];
  mealSurveyVisible: Visibility;
  types: string[];
  calories: number;
  carbs: number;
  fibre: number;
  proteins: number;
  fats: number;
}

export interface ProductEditState {
  isLoading: boolean;
  hasError: boolean;
  product: ProductData;
}

export interface ProductSubmitState {
  isLoading: boolean;
  hasError: boolean;
  error?: Error;
}

// Products part of global store
export interface ProductsState {
  isLoading: boolean;
  hasError: boolean;
  listItems: Array<ProductListItem>;
  edit: ProductEditState;
  submit: ProductSubmitState;
  types: string[];
}

export const PRODUCTS_LIST_LOAD = 'PRODUCTS_LIST_LOAD';
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS';
export const PRODUCTS_LIST_ERROR = 'PRODUCTS_LIST_ERROR';

export const PRODUCTS_TYPES_LOAD = 'PRODUCTS_TYPES_LOAD';
export const PRODUCTS_TYPES_SUCCESS = 'PRODUCTS_TYPES_SUCCESS';
export const PRODUCTS_TYPES_ERROR = 'PRODUCTS_TYPES_ERROR';

export const PRODUCTS_EDIT_LOAD = 'PRODUCTS_EDIT_LOAD';
export const PRODUCTS_EDIT_SUCCESS = 'PRODUCTS_EDIT_SUCCESS';
export const PRODUCTS_EDIT_ERROR = 'PRODUCTS_EDIT_ERROR';

export const PRODUCTS_UPDATE_LOAD = 'PRODUCTS_UPDATE_LOAD';
export const PRODUCTS_UPDATE_SUCCESS = 'PRODUCTS_UPDATE_SUCCESS';
export const PRODUCTS_UPDATE_ERROR = 'PRODUCTS_UPDATE_ERROR';

export const PRODUCTS_NEW_LOAD = 'PRODUCTS_NEW_LOAD';
export const PRODUCTS_NEW_SUCCESS = 'PRODUCTS_NEW_SUCCESS';
export const PRODUCTS_NEW_ERROR = 'PRODUCTS_NEW_ERROR';

interface ProductListLoadAction {
  type: typeof PRODUCTS_LIST_LOAD;
}

interface ProductListSuccessAction {
  type: typeof PRODUCTS_LIST_SUCCESS;
  payload: Array<ProductListItem>;
}

interface ProductListErrorAction {
  type: typeof PRODUCTS_LIST_ERROR;
}

interface ProductTypesLoadAction {
  type: typeof PRODUCTS_TYPES_LOAD;
}

interface ProductTypesSuccessAction {
  type: typeof PRODUCTS_TYPES_SUCCESS;
  payload: string[];
}

interface ProductTypesErrorAction {
  type: typeof PRODUCTS_TYPES_ERROR;
}

interface ProductEditLoadAction {
  type: typeof PRODUCTS_EDIT_LOAD;
}

interface ProductEditSuccessAction {
  type: typeof PRODUCTS_EDIT_SUCCESS;
  payload: ProductData;
}

interface ProductEditErrorAction {
  type: typeof PRODUCTS_EDIT_ERROR;
}

interface ProductUpdateLoadAction {
  type: typeof PRODUCTS_UPDATE_LOAD;
}

interface ProductUpdateSuccessAction {
  type: typeof PRODUCTS_UPDATE_SUCCESS;
}

interface ProductUpdateErrorAction {
  type: typeof PRODUCTS_UPDATE_ERROR;
  payload: Error;
}

interface ProductNewLoadAction {
  type: typeof PRODUCTS_NEW_LOAD;
}

interface ProductNewSuccessAction {
  type: typeof PRODUCTS_NEW_SUCCESS;
}

interface ProductNewErrorAction {
  type: typeof PRODUCTS_NEW_ERROR;
  payload: Error;
}

export type ProductActionTypes =
  | ProductListLoadAction
  | ProductListSuccessAction
  | ProductListErrorAction
  | ProductTypesLoadAction
  | ProductTypesSuccessAction
  | ProductTypesErrorAction
  | ProductEditLoadAction
  | ProductEditSuccessAction
  | ProductEditErrorAction
  | ProductUpdateLoadAction
  | ProductUpdateSuccessAction
  | ProductUpdateErrorAction
  | ProductNewLoadAction
  | ProductNewSuccessAction
  | ProductNewErrorAction;
