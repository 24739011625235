import {
  PAYMENT_PROVIDER_RULE_CURRENT_LOAD,
  PAYMENT_PROVIDER_RULE_CURRENT_SAVE,
  PAYMENT_PROVIDER_RULE_CURRENT_SAVE_ERROR,
  PAYMENT_PROVIDER_RULE_CURRENT_SAVE_SUCCESS,
  PAYMENT_PROVIDER_RULE_CURRENT_SUCCESS,
  PAYMENT_PROVIDER_RULE_LIST_ERROR,
  PAYMENT_PROVIDER_RULE_LIST_LOAD,
  PAYMENT_PROVIDER_RULE_LIST_SUCCESS,
  PaymentProviderRuleActionTypes,
  PaymentProviderRuleState,
} from './types';

const initialState: PaymentProviderRuleState = {
  isLoading: false,
  hasError: false,
  actionState: {
    paymentProviderRuleSave: {
      isLoading: false,
      hasUpdated: false,
    },
  },
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  current: {
    data: {
      id: '',
      error: '',
      type: null,
      delay_days: null,
      created_at: '',
      updated_at: '',
    },
  },
};

const paymentProviderRuleReducer = (
  state = initialState,
  action: PaymentProviderRuleActionTypes
): PaymentProviderRuleState => {
  switch (action.type) {
    case PAYMENT_PROVIDER_RULE_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
        actionState: {
          paymentProviderRuleSave: {
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case PAYMENT_PROVIDER_RULE_CURRENT_LOAD:
    case PAYMENT_PROVIDER_RULE_CURRENT_SAVE:
      return {
        ...state,
        actionState: {
          paymentProviderRuleSave: {
            isLoading: true,
            hasUpdated: false,
          },
        },
      };

    case PAYMENT_PROVIDER_RULE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case PAYMENT_PROVIDER_RULE_CURRENT_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          paymentProviderRuleSave: {
            isLoading: false,
            hasUpdated: false,
            error: undefined,
          },
        },
      };

    case PAYMENT_PROVIDER_RULE_CURRENT_SAVE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          paymentProviderRuleSave: {
            isLoading: false,
            hasUpdated: true,
            error: undefined,
          },
        },
      };

    case PAYMENT_PROVIDER_RULE_CURRENT_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          paymentProviderRuleSave: {
            error: action.data,
            isLoading: false,
            hasUpdated: false,
          },
        },
      };

    case PAYMENT_PROVIDER_RULE_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default paymentProviderRuleReducer;
