import { LocalizedValues, MediaType } from 'types';

export interface MealsListItem {
  id: string;
  title: string;
  code: string;
  updated: string;
  state: string;
}

export interface FormData {
  recipeTypes: string[];
  mealTypes: string[];
  products: ProductFormType[];
  benefits: string[];
}

export interface ProductFormType {
  title: string;
  id: number;
}

export interface IngredientType {
  product: {
    id: number;
    title: string;
  };
  amount: string;
  optional: boolean;
}

export interface IngredientPostType {
  productId: number;
  amount: string;
  optional: boolean;
}

export interface MealData {
  updated: string;
  code: string;
  state: string;
  titles: LocalizedValues<string>;
  recipes: LocalizedValues<string>;
  intros: LocalizedValues<string>;
  images: LocalizedValues<MediaType>;
  cookingTime: string;
  defaultImage: MediaType;
  mealTypes: string[];
  recipeTypes: string[];
  goals: string[];
  benefits: string[];
  ingredients: IngredientType[];
}

export interface MealPostData {
  updated: string;
  code: string;
  state: string;
  titles: LocalizedValues<string>;
  recipes: LocalizedValues<string>;
  intros: LocalizedValues<string>;
  images: LocalizedValues<string>;
  cookingTime: string;
  defaultImage: string;
  mealTypes: string[];
  recipeTypes: string[];
  goals: string[];
  benefits: string[];
  ingredients: IngredientPostType[];
}

export interface MealEditState {
  isLoading: boolean;
  hasError: boolean;
  meal: MealData;
}

export interface MealSubmitState {
  isLoading: boolean;
  hasError: boolean;
  error?: Error;
}

// Meals part of global store
export interface MealsState {
  isLoading: boolean;
  hasError: boolean;
  listItems: MealsListItem[];
  edit: MealEditState;
  formData: FormData;
  submit: MealSubmitState;
}

export const MEAL_LIST_LOAD = 'MEAL_LIST_LOAD';
export const MEAL_LIST_SUCCESS = 'MEAL_LIST_SUCCESS';
export const MEAL_LIST_ERROR = 'MEAL_LIST_ERROR';

export const MEAL_FORM_DATA_LOAD = 'MEAL_FORM_DATA_LOAD';
export const MEAL_FORM_DATA_SUCCESS = 'MEAL_FORM_DATA_SUCCESS';
export const MEAL_FORM_DATA_ERROR = 'MEAL_FORM_DATA_ERROR';

export const MEAL_EDIT_DATA_LOAD = 'MEAL_EDIT_DATA_LOAD';
export const MEAL_EDIT_DATA_SUCCESS = 'MEAL_EDIT_DATA_SUCCESS';
export const MEAL_EDIT_DATA_ERROR = 'MEAL_EDIT_DATA_ERROR';

export const MEAL_UPDATE_LOAD = 'MEAL_UPDATE_LOAD';
export const MEAL_UPDATE_SUCCESS = 'MEAL_UPDATE_SUCCESS';
export const MEAL_UPDATE_ERROR = 'MEAL_UPDATE_ERROR';

export const MEAL_NEW_LOAD = 'MEAL_NEW_LOAD';
export const MEAL_NEW_SUCCESS = 'MEAL_NEW_SUCCESS';
export const MEAL_NEW_ERROR = 'MEAL_NEW_ERROR';

interface MealListLoadAction {
  type: typeof MEAL_LIST_LOAD;
}

interface MealListSuccess {
  type: typeof MEAL_LIST_SUCCESS;
  payload: MealsListItem[];
}

interface MealListError {
  type: typeof MEAL_LIST_ERROR;
}

interface MealFormDataLoadAction {
  type: typeof MEAL_FORM_DATA_LOAD;
}

interface MealFormDataSuccess {
  type: typeof MEAL_FORM_DATA_SUCCESS;
  payload: FormData;
}

interface MealFormDataError {
  type: typeof MEAL_FORM_DATA_ERROR;
}

interface MealEditDataLoadAction {
  type: typeof MEAL_EDIT_DATA_LOAD;
}

interface MealEditDataSuccess {
  type: typeof MEAL_EDIT_DATA_SUCCESS;
  payload: MealData;
}

interface MealEditDataError {
  type: typeof MEAL_EDIT_DATA_ERROR;
}

interface MealUpdateLoadAction {
  type: typeof MEAL_UPDATE_LOAD;
}

interface MealUpdateSuccess {
  type: typeof MEAL_UPDATE_SUCCESS;
}

interface MealUpdateError {
  type: typeof MEAL_UPDATE_ERROR;
  payload: Error;
}

interface MealNewLoadAction {
  type: typeof MEAL_NEW_LOAD;
}

interface MealNewSuccess {
  type: typeof MEAL_NEW_SUCCESS;
}

interface MealNewError {
  type: typeof MEAL_NEW_ERROR;
  payload: Error;
}

export type MealActionTypes =
  | MealListLoadAction
  | MealListSuccess
  | MealListError
  | MealFormDataLoadAction
  | MealFormDataSuccess
  | MealFormDataError
  | MealEditDataLoadAction
  | MealEditDataSuccess
  | MealEditDataError
  | MealUpdateLoadAction
  | MealUpdateSuccess
  | MealUpdateError
  | MealNewLoadAction
  | MealNewSuccess
  | MealNewError;
