/* eslint-disable import/prefer-default-export */
import * as t from './types';

const initialState: t.AuditEventState = {
  isLoading: false,
  hasError: false,
  userAuditEvent: null,
};

const auditEventReducer = (
  state = initialState,
  action: t.AuditEventActionTypes
): t.AuditEventState => {
  switch (action.type) {
    case t.USER_AUDIT_EVENT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case t.USER_AUDIT_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userAuditEvent: action.payload,
      };
    case t.USER_AUDIT_EVENT_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default auditEventReducer;
