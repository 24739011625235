import * as t from './types';

const initialState: t.ModalsState = {
  modals: [],
};

const modalReducer = (
  state = initialState,
  action: t.ModalActionTypes
): t.ModalsState => {
  switch (action.type) {
    case t.MODAL_ADD:
      return {
        ...state,
        modals: [...state.modals, action.payload],
      };

    case t.MODAL_REMOVE:
      return {
        ...state,
        modals: state.modals.slice(1),
      };

    default:
      return {
        ...state,
      };
  }
};

export default modalReducer;
