import { MonacofitLocales } from 'types';
import { DropdownProps } from 'semantic-ui-react';
import { PaginationMetaData } from 'types/pagination';

export enum OfferGenders {
  Any = 'any',
  Female = 'female',
  Male = 'male',
}

type DisplayData = {
  [key: string]: string;
};

type ExtraData = {
  [key: string]: string;
};

export enum AdditionalDataKeys {
  displayData = 'display_data',
  extraData = 'extra_data',
}

export enum PaymentType {
  recurring = 0,
  single = 1,
}

export enum OfferPriceType {
  standard = 'standard',
  chance = 'chance',
  unsubscribe = 'unsubscribe',
  firstRecovery = 'first_recovery',
  secondRecovery = 'second_recovery',
  trial = 'trial',
  timer = 'timer',
}

export type OfferFilters = {
  payMode: string;
  locale: string;
  country: string;
  offerType: string;
  currency: string;
  page: number | string;
  id: string;
};

export type OffersBatchCopyParams = {
  from_locale: string;
  from_country: string;
  from_currency: string;
  to_locale: string;
  to_country: string;
  to_currency: string;
  ignore_existing: boolean;
};

export type Pricing = {
  amount: string;
  amount_in_euro: string;
  display_data: DisplayData[];
  display_price: string;
  payment_type: number;
  type: string;
  extra_data: ExtraData[];
};

export type OfferPrice = {
  id: number;
  amount: number;
  amount_in_euro: number;
  display_data: DisplayData;
  display_price: string;
  payment_type: PaymentType;
  type: OfferPriceType;
  extra_data: ExtraData;
};

export type PriceError = {
  id: number;
  key: string;
};

export type AttachableOffer = {
  id: number;
  sequence: number;
  default_selected: boolean;
};

export enum OFFER_MODE {
  single = 'single',
  attached = 'attached',
  multi = 'multi',
}

export interface Offer {
  title: string;
  categories: Array<number>;
  offerType: string;
  locale: MonacofitLocales;
  country: string;
  amount: number | string;
  priceInEuro: number | string;
  displayAmount: string;
  displayText: string;
  currencyId: number | string;
  displayCurrency: string;
  displayDiscount: string;
  displayOldPrice: string;
  chanceAmount: number | string;
  chancePriceInEuro: number | string;
  displayChanceAmount: string;
  displayChanceDiscount: string;
  fdlDesignFile: string;
  designFile: string;
  templateName: string;
  gender: OfferGenders;
  offer_prices: OfferPrice[];
  instantChargeableConfirmationCategories?: number[];
  show_tos: boolean;
  auto_accept_tos: boolean;
  inline_checkout: boolean;
  funds_recovery: boolean;
  preload_checkout: boolean;
  is_rtl_checkout: boolean;
  has_delayed_charge: boolean;
  cancellation_fee_offer_id?: number | string;
  attached_child_offers: AttachableOffer[];
  attached_parent_offers: AttachableOffer[];
  mode: OFFER_MODE;
  [key: string]:
    | string
    | number
    | boolean
    | Array<number | string | PurchaseItem>
    | OfferContentObject
    | FirstDataAccount
    | OfferPrice[]
    | AttachableOffer[]
    | undefined;
}

export interface OfferFormProps {
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onSelectChange?(
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ): void;
  errors?: Array<string>;
  editable?: boolean;
  editOffer?: EditOffer;
  inputValues: Offer | EditOffer;
}

export interface PurchaseItem {
  id: number;
  label: string;
  main: boolean;
}

export interface OfferContentObject {
  [key: string]: string | Array<string>;
}

export interface EditOffer extends Offer {
  updatedAt: string;
  content?: OfferContentObject;
  acceptButtonDisplayText: string;
  rejectButtonDisplayText: string;
  state: string;
  paymodes: Array<string>;
  discounted: boolean;
  purchaseItems: Array<PurchaseItem>;
  firstDataAccount: FirstDataAccount;
}

export interface OfferListItem {
  id: number;
  state: string;
  country: string;
  locale: string;
  title: string;
  displayAmount: string;
  displayChanceAmount: string;
  paymodes: Array<string>;
  [key: string]: string | number | Array<string>;
}

export type OfferListType = Array<OfferListItem>;

export interface OfferListResponse {
  data: OfferListType;
  meta: PaginationMetaData;
}

export interface CurrenyObject {
  id: number;
  label: string;
}

export interface PurchaseListItem {
  id: number;
  label: string;
}

export type FirstDataAccount = {
  id: number;
  mid: number;
  active: boolean;
  bank_name: string;
};

export interface PixelAccountListItem {
  id: number | undefined;
  name: string;
  pixel_id: string;
}

export interface FormDataType {
  offerTypes: Array<string>;
  countries: Array<string>;
  currencies: Array<CurrenyObject>;
  firstDataAccounts: FirstDataAccount[];
  states: Array<string>;
  paymodes: Array<string>;
  purchaseItems: Array<PurchaseListItem>;
  pixelAccounts: PixelAccountListItem[];
}

// Offers part of global store
export interface OfferState {
  isLoading: boolean;
  hasError: boolean;
  listItems: OfferListResponse;
  formData: FormDataType;
  create: {
    isLoading: boolean;
    hasError: boolean;
  };
  edit: {
    isLoading: boolean;
    hasError: boolean;
    offer: EditOffer;
  };
  duplicate: {
    isLoading: boolean;
    hasError: boolean;
  };
  newPrice: {
    isLoading: boolean;
    hasError: boolean;
    isSuccess: boolean;
  };
}

// Describing the different ACTION NAMES available
export const OFFER_LIST_LOAD = 'OFFER_LIST_LOAD';
export const OFFER_LIST_SUCCESS = 'OFFER_LIST_SUCCESS';
export const OFFER_LIST_ERROR = 'OFFER_LIST_ERROR';

export const OFFER_FORM_DATA_LOAD = 'OFFER_FORM_DATA_LOAD';
export const OFFER_FORM_DATA_SUCCESS = 'OFFER_FORM_DATA_SUCCESS';
export const OFFER_FORM_DATA_ERROR = 'OFFER_FORM_DATA_ERROR';

export const OFFER_CREATE = 'OFFER_CREATE';
export const OFFER_CREATE_SUCCESS = 'OFFER_CREATE_SUCCESS';
export const OFFER_CREATE_ERROR = 'OFFER_CREATE_ERROR';
export const CLEAR_CREATE_ERRORS = 'CLEAR_CREATE_ERRORS';

export const REQUEST_OFFER = 'REQUEST_OFFER';
export const REQUEST_OFFER_SUCCESS = 'REQUEST_OFFER_SUCCESS';
export const REQUEST_OFFER_ERROR = 'REQUEST_OFFER_ERROR';

export const OFFER_EDIT = 'OFFER_EDIT';
export const OFFER_EDIT_SUCCESS = 'OFFER_EDIT_SUCCESS';
export const OFFER_EDIT_ERROR = 'OFFER_EDIT_ERROR';
export const CLEAR_EDIT_ERRORS = 'CLEAR_EDIT_ERRORS';

export const OFFER_DUPLICATE = 'OFFER_DUPLICATE';
export const OFFER_DUPLICATE_SUCCESS = 'OFFER_DUPLICATE_SUCCESS';
export const OFFER_DUPLICATE_ERROR = 'OFFER_DUPLICATE_ERROR';

export const OFFER_ADD_NEW_PRICE = 'OFFER_ADD_NEW_PRICE';
export const OFFER_ADD_NEW_PRICE_SUCCESS = 'OFFER_ADD_NEW_PRICE_SUCCESS';
export const OFFER_ADD_NEW_PRICE_ERROR = 'OFFER_ADD_NEW_PRICE_ERROR';

interface OfferListLoadAction {
  type: typeof OFFER_LIST_LOAD;
}

interface OfferListSuccess {
  type: typeof OFFER_LIST_SUCCESS;
  payload: OfferListResponse;
}

interface OfferListError {
  type: typeof OFFER_LIST_ERROR;
}

interface OfferFormDataLoadAction {
  type: typeof OFFER_FORM_DATA_LOAD;
}

interface OfferFormDataSuccess {
  type: typeof OFFER_FORM_DATA_SUCCESS;
  payload: FormDataType;
}

interface OfferFormDataError {
  type: typeof OFFER_FORM_DATA_ERROR;
}

interface OfferCreateAction {
  type: typeof OFFER_CREATE;
}

interface OfferCreateSuccess {
  type: typeof OFFER_CREATE_SUCCESS;
}

interface OfferCreateError {
  type: typeof OFFER_CREATE_ERROR;
}

interface ClearCreateErrors {
  type: typeof CLEAR_CREATE_ERRORS;
}

interface RequestOfferAction {
  type: typeof REQUEST_OFFER;
}

interface RequestOfferSuccess {
  type: typeof REQUEST_OFFER_SUCCESS;
  payload: EditOffer;
}

interface RequestOfferError {
  type: typeof REQUEST_OFFER_ERROR;
}

interface OfferEditAction {
  type: typeof OFFER_EDIT;
}

interface OfferEditSuccess {
  type: typeof OFFER_EDIT_SUCCESS;
}

interface OfferEditError {
  type: typeof OFFER_EDIT_ERROR;
}

interface ClearEditErrors {
  type: typeof CLEAR_EDIT_ERRORS;
}

interface OfferDuplicateAction {
  type: typeof OFFER_DUPLICATE;
}

interface OfferDuplicateSuccess {
  type: typeof OFFER_DUPLICATE_SUCCESS;
}

interface OfferDuplicateError {
  type: typeof OFFER_DUPLICATE_ERROR;
}
interface OfferAddNewPriceAction {
  type: typeof OFFER_ADD_NEW_PRICE;
}

interface OfferAddNewPriceSuccess {
  type: typeof OFFER_ADD_NEW_PRICE_SUCCESS;
}

interface OfferAddNewPriceError {
  type: typeof OFFER_ADD_NEW_PRICE_ERROR;
}

export type OfferActionTypes =
  | OfferListLoadAction
  | OfferListSuccess
  | OfferListError
  | OfferFormDataLoadAction
  | OfferFormDataSuccess
  | OfferFormDataError
  | OfferCreateAction
  | OfferCreateSuccess
  | OfferCreateError
  | ClearCreateErrors
  | RequestOfferAction
  | RequestOfferSuccess
  | RequestOfferError
  | OfferEditAction
  | OfferEditSuccess
  | OfferEditError
  | ClearEditErrors
  | OfferDuplicateAction
  | OfferDuplicateSuccess
  | OfferDuplicateError
  | OfferAddNewPriceAction
  | OfferAddNewPriceSuccess
  | OfferAddNewPriceError;
